import styled from "styled-components/macro"

type StyledProps = {
  $padding?: string
  $disabled?: boolean
}

export const ProductVariantRowContainer = styled.div<StyledProps>`
  padding: ${(p) => p.$padding ?? "2rem 1rem"};
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 0.5rem;
  border-radius: 0.6rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 6rem;
    margin-right: 0.5rem;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Price = styled.div`
  display: flex;
  flex-direction: column;
`

export const BasePrice = styled.div`
  text-decoration: line-through;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
`

export const Stock = styled.div`
  margin-top: 1rem;
`

export const Quantity = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const EditButton = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 50%;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.background};
  }

  svg {
    height: 1.2rem;
  }
`

export const QtyButton = styled.div`
  height: 2.3rem;
  width: 2.3rem;
  padding: 0.7rem;
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  border-radius: 50%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.background};
  }
`

export const Qty = styled.div`
  margin: 0 1rem;
`

export const CloseIcon = styled.div`
  right: 1rem;
  top: 1rem;
  border: ${(p) => p.theme.colors.grey} 0.1rem solid;
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.background};
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const DiscountInputWrapper = styled.div`
  div {
    padding-bottom: 0.5rem;
  }

  margin-bottom: 2rem;
`

export const ErrorText = styled.div`
  color: ${(p) => p.theme.colors.errorText};
  font-size: 1.1rem;
`

export const TotalPrice = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 2rem;
  border-radius: 0.6rem;
  border: 1px solid ${(p) => p.theme.colors.borderLight};
  margin-bottom: 2rem;

  div {
    display: flex;
    justify-content: space-between;
  }
`

export const Error = styled.div`
  color: ${(p) => p.theme.colors.errorText};
  font-weight: bold;
  border: 1px solid ${(p) => p.theme.colors.errorText};
  background: ${(p) => p.theme.colors.errorBackground};
  padding: 0 1rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-size: 1rem;
`

export const Variants = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  flex-direction: column;
`

export const Variant = styled.span<StyledProps>`
  img {
    height: 4rem;
  }

  background: ${(p) => (p.$disabled ? p.theme.colors.greyLight : p.theme.colors.white)};
  padding: 1rem;
  border-radius: 0.6rem;
  border: 1px solid ${(p) => p.theme.colors.borderLight};

  &:hover {
    cursor: ${(p) => (p.$disabled ? "default" : "pointer")};
    background: ${(p) => (p.$disabled ? p.theme.colors.greyLight : p.theme.colors.background)};
  }
  position: relative;
`

export const Status = styled.div<StyledProps>`
  background: ${p => p.theme.colors.error.background};
  color: ${p => p.theme.colors.white};
  font-weight: 700;
  position: absolute;
  transform-origin: 50% 50%;
  font-size: 1.1rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  left: -2.8rem;
  top: 1.9rem;
  rotate: -45deg;
  clip-path: polygon(22% 0, 78% 0, 100% 100%, 100% 0%, 100% 100%, 0% 100%);
`

export const LoadingContainer = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`
