import React, { useEffect, useState } from "react"
import Box from "../../../components/Ui/Box"
import { TabText } from "./ManageOrder.styled"
import { useQuery } from "@apollo/client"
import GET_ORDER from "../../../graphql/queries/order/GetOrder"
import { useNavigate, useParams } from "react-router-dom"

import * as graphql from "lib/types/generated/graphql-types"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import CancelOrder from "../../../components/Order/ManageOrder/CancelOrder/CancelOrder"
import FailedCancellation from "../../../components/Order/ManageOrder/CancelOrder/FailedCancellation"
import RefundOrder from "../../../components/Order/ManageOrder/RefundOrder/RefundOrder"
import DeliveryOrder from "../../../components/Order/ManageOrder/DeliverOrder/DeliveryOrder"
import CompensationOrder from "../../../components/Order/ManageOrder/CompensationOrder/CompensationOrder"
import { NoticeBanner } from "../../../components/Ui/Messages/NoticeBanner"
import ReleaseOrder from "../../../components/Order/ManageOrder/ReleaseOrder/ReleaseOrder"
import PendingCancellation from "../../../components/Order/ManageOrder/CancelOrder/PendingCancellation"
import ModifyOrder from "../../../components/Order/ManageOrder/ModifyOrder/ModifyOrder"

const ManageOrder = () => {
  const { id } = useParams()
  const { data, refetch, loading } = useQuery(GET_ORDER, { variables: { id } })
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const navigate = useNavigate()

  const order = data?.Order?.details
  const orderStatus = order?.status as graphql.OrderStatus
  const orderHistory = data?.Order?.history as graphql.OrderHistory

  const failedDeliveries = order?.Deliveries?.filter(
    (delivery: graphql.OrderDelivery) => !!delivery.failed
  )
  const failedModifications = order?.Modifications?.filter(
    (modification: graphql.OrderModification) => !!modification.failed
  )
  const failedReleases = order?.Releases?.filter(
    (release: graphql.OrderRelease) => !!release.failed
  )
  const failedCompensations = order?.Compensations?.filter(
    (compensation: graphql.OrderCompensation) => !!compensation.failed
  )
  const failedRefunds = order?.Refunds?.filter((refund: graphql.OrderRefund) => !!refund.failed)
  const failedCancellation = !!order?.Cancellations[0]?.failed
  const pendingCancellations = order?.Cancellations?.filter(
    (cancellation: graphql.OrderCancellation) =>
      !cancellation.completed && cancellation.started && !cancellation.failed
  )
  const pendingModifications = order?.Modifications?.filter(
    (modification: graphql.OrderCancellation) =>
      !modification.completed && modification.started && !modification.failed
  )
  const completedCompensationsExists =
    order?.Compensations.filter((compensation: graphql.OrderCompensation) => compensation.completed)
      .length > 0
  const completedRefundsExists =
    order?.Refunds.filter((refund: graphql.OrderRefund) => refund.completed).length > 0
  const completedDeliveriesExists =
    order?.Deliveries.filter((delivery: graphql.OrderDelivery) => delivery.completed).length > 0
  const completedReleasesExists =
    order?.Releases.filter((release: graphql.OrderRelease) => release.completed).length > 0

  const showDelivery = () =>
    !completedReleasesExists &&
    pendingCancellations?.length === 0 &&
    pendingModifications.length < 1
  const showRelease = () =>
    !completedReleasesExists &&
    !orderStatus?.orderStates?.includes(graphql.OrderState.FullyDelivered) &&
    completedDeliveriesExists
  const showCompensation = () => !completedRefundsExists && completedDeliveriesExists
  const showRefund = () => !completedCompensationsExists && completedDeliveriesExists
  const showModify = () => order && !completedDeliveriesExists && pendingCancellations.length < 1

  useEffect(() => {
    if (order?.Cancellations[0]?.completed) {
      navigate(`/orders/${id}`)
    }
  }, [order?.Cancellations])

  if (failedCancellation)
    return (
      <Box padding="0" margin="0">
        <PageHeader
          title={"Manage order #" + (order?.reference ?? "")}
          description={<>Manage order</>}
          goBackLinkUrl={`/orders/${id}`}
          goBackLinkText="Back to order"
          hasTabs
        ></PageHeader>
        <FailedCancellation
          failedCancellation={order?.Cancellations[0]}
          orderId={order.id}
          refetch={refetch}
          orderHistory={orderHistory?.history ?? []}
        />
      </Box>
    )

  return (
    <Box padding="0" margin="0">
      <PageHeader
        title={"Manage order #" + (order?.reference ?? "")}
        description={<>Manage order</>}
        goBackLinkUrl={`/orders/${id}`}
        goBackLinkText="Back to order"
        hasTabs
      >
        {((order?.Cancellations.length === 0 && order.Deliveries.length === 0) ||
          (order?.Cancellations.length > 0 && !order?.Cancellations[0].completed)) && (
          <PrimaryButton mode="delete" handleClick={() => setShowCancelPopup(true)}>
            Cancel order
          </PrimaryButton>
        )}
      </PageHeader>
      <CancelOrder
        setShowCancelPopup={setShowCancelPopup}
        showCancelPopup={showCancelPopup}
        order={order}
        refetch={refetch}
      />
      <Tabs>
        <TabList>
          {showDelivery() && <Tab>Deliveries</Tab>}
          {showModify() && <Tab>Modify order lines</Tab>}
          {completedDeliveriesExists && (
            <>
              {showRelease() && <Tab>Releases</Tab>}
              {!completedCompensationsExists && <Tab>Refunds</Tab>}
              {showCompensation() && <Tab>Compensations</Tab>}
            </>
          )}
          {failedCancellation ||
            (pendingCancellations?.length > 0 && (
              <Tab>
                <TabText>Cancellations</TabText>
              </Tab>
            ))}
        </TabList>
        {showDelivery() && (
          <TabPanel>
            <DeliveryOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedDeliveries={failedDeliveries}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showModify() && (
          <TabPanel>
            <ModifyOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedModifications={failedModifications}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showRelease() && (
          <TabPanel>
            <ReleaseOrder
              order={order}
              refetch={refetch}
              loading={loading}
              failedReleases={failedReleases}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showRefund() && (
          <TabPanel>
            {!completedRefundsExists && !completedCompensationsExists && (
              <NoticeBanner type="reminder">{`You cannot issue both a refund and compensation for the same order. Once a refund is initiated, compensation cannot be applied to the order.`}</NoticeBanner>
            )}
            <RefundOrder
              order={order}
              refetch={refetch}
              failedRefunds={failedRefunds}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {showCompensation() && (
          <TabPanel>
            {!completedRefundsExists && !completedCompensationsExists && (
              <NoticeBanner type="reminder">{`You cannot issue both a compensation and refund for the same order. Once a compensation is initiated, refund cannot be applied to the order.`}</NoticeBanner>
            )}
            <CompensationOrder
              order={order}
              refetch={refetch}
              failedCompensations={failedCompensations}
              loading={loading}
              orderHistory={orderHistory}
            />
          </TabPanel>
        )}
        {pendingCancellations?.length > 0 && (
          <TabPanel>
            <PendingCancellation
              pendingCancellation={pendingCancellations[0]}
              orderRefetch={refetch}
            />
          </TabPanel>
        )}
      </Tabs>
    </Box>
  )
}

export default ManageOrder
