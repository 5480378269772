import React from "react"
import { DeliveryGift, Order, OrderRefund } from "@lib/types/generated/graphql-types"
import { Id, Name, ProductImage, RefundButtonWrapper, Row } from "../Shared.styled"

import placeholder from "../../../../../images/placeholder.svg"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import CREATE_REFUND from "../../../../../graphql/mutations/order/refunds/CreateRefund"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { ReactComponent as RefundIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import Status from "../../../../Ui/Status"
import { getRefundInput } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"

type Props = {
  deliveryGift: DeliveryGift
  order: Order
  currencyCode: string
  refunds: OrderRefund[]
  refetch: () => void
}

export const GiftRow = ({ deliveryGift, order, currencyCode, refunds, refetch }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }
  const notCompletedRefund = refunds.find((refund) => !refund.started)

  const orderLineRefunds = refunds.filter((refund) => !!refund.completed).flatMap((refund) =>
    refund?.gifts?.filter((gift) => gift.giftId === deliveryGift?.giftId)
  )
  const getRefundedQuantity = () => orderLineRefunds.reduce((a, b) => { return a + (b?.quantity ?? 0)}, 0)

  const getOrderGift = () => {
    return order?.gifts?.find(ol => ol?.id === deliveryGift?.giftId)
  }

  const [createRefund, { loading: createLoading }] = useMutation(CREATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
    handleErrorMessages(error)
    }
  })

  const [updateRefund, { loading: updateLoading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const giftLineExistInRefund = (id: string) => {
    return !!notCompletedRefund?.gifts?.find((gift) => gift.giftId === id)
  }

  const createOrUpdateOrderRefund = () => {
    if (!notCompletedRefund) {
      return createRefund({
        variables: {
          orderId: order.id,
          input: { gifts: { giftId: deliveryGift.giftId, quantity: deliveryGift.quantity - getRefundedQuantity()} }
        }
      })
    }
    return updateRefund({
      variables: {
        refundId: notCompletedRefund.id,
        input: {
          ...getRefundInput(notCompletedRefund),
          gifts: [
            ...notCompletedRefund.gifts.map((gift) => ({
              giftId: gift.giftId,
              quantity: gift.quantity
            })),
            { giftId: deliveryGift.giftId, quantity: deliveryGift.quantity - getRefundedQuantity() }
          ]
        }
      }
    })
  }

  return (
    <Row key={deliveryGift.giftId}>
      <ProductImage src={getOrderGift()?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{getOrderGift()?.name}</Name>
        <Money
          amount={getOrderGift()?.totalPriceAmount ?? 0}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
        <div>Order quantity: {deliveryGift?.quantity}</div>
        {getRefundedQuantity() > 0 && <div>Refunded quantity: {getRefundedQuantity()}</div>}
        <Id>
          <b>Variant ID:</b> <span>{getOrderGift()?.productVariantId}</span>
        </Id>
      </div>
      <RefundButtonWrapper>
        {getRefundedQuantity() === deliveryGift?.quantity ? <Status status="Refunded"/>: <SecondaryButton
          handleClick={createOrUpdateOrderRefund}
          disabled={!!giftLineExistInRefund(deliveryGift.giftId)}
          loading={createLoading || updateLoading}
        >
          <RefundIcon />
          Refund
        </SecondaryButton>}
        <span>
          Total :
          <b>
            <Money
              amount={getOrderGift()?.totalPriceAmount ?? 0 * deliveryGift.quantity}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </RefundButtonWrapper>
    </Row>
  )
}
