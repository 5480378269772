import React from "react"
import { getLogicTypeDisplayName } from "helpers/getLogicTypeDisplayName"

import { LogicSelect } from "./LogicSelect"
import { InputOutcomeType } from "lib/types/generated/graphql-types"

const options = [
  {
    value: InputOutcomeType.Cartdiscount,
    label: getLogicTypeDisplayName(InputOutcomeType.Cartdiscount),
    description: "Apply a discount to the entire cart value."
  },
  {
    value: InputOutcomeType.Buyget,
    label: getLogicTypeDisplayName(InputOutcomeType.Buyget),
    description: "Buy one pair of socks, get discount on another pair."
  },
  {
    value: InputOutcomeType.Buyselect,
    label: getLogicTypeDisplayName(InputOutcomeType.Buyselect),
    description: "Buy a pair of socks, get a discount on something else."
  },
  {
    value: InputOutcomeType.Itemdiscounts,
    label: getLogicTypeDisplayName(InputOutcomeType.Itemdiscounts),
    description: "Apply discounts to specific products in the cart."
  },
  {
    value: InputOutcomeType.Selectitems,
    label: getLogicTypeDisplayName(InputOutcomeType.Selectitems),
    description: "Item discount with quantity. Get discount on most expensive item"
  },
  {
    value: InputOutcomeType.Optimizebuygets,
    label: getLogicTypeDisplayName(InputOutcomeType.Optimizebuygets),
    description: "3 for 2, or buy 2 and get 50% off."
  },
  {
    value: InputOutcomeType.Freeshipping,
    label: getLogicTypeDisplayName(InputOutcomeType.Freeshipping),
    description: "Qualify for free shipping when a condition is met."
  },
  {
    value: InputOutcomeType.Gift,
    label: getLogicTypeDisplayName(InputOutcomeType.Gift),
    description: "A selected product is automatically added to the cart."
  }
  ,
  {
    value: InputOutcomeType.Shippingtags,
    label: getLogicTypeDisplayName(InputOutcomeType.Shippingtags),
    description: "Custom shipping tags to be handled by provider"
  }
]

type Props = {
  setValue: any // eslint-disable-line
  defaultValue?: string
  disabled?: boolean
}

export const OutcomeSelect = ({ setValue, disabled, defaultValue }: Props) => (
  <LogicSelect
    options={options}
    setValue={setValue}
    disabled={disabled}
    placeholder="Select outcome type to add"
    defaultValue={defaultValue}
  />
)
