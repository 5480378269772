import React from "react"
import { useQuery } from "@apollo/client"
import esb from "elastic-builder"
import dayjs from "dayjs"
import { Query } from "@lib/types/common"
import { useNavigate } from "react-router-dom"

import SEARCH_ORDERS_TOTAL_HITS from "graphql/queries/order/SearchOrdersTotalHits"

import { CountCard } from "./CountCard"

import { ReactComponent as OrderIcon } from "images/icons/cart-shopping.svg"

type Props = {
  timeZone: string
}

export const DailyOrders = ({ timeZone }: Props) => {
  const navigate = useNavigate()

  const dailyRevenueQuery = esb
    .requestBodySearch()
    .query(
      esb
        .boolQuery()
        .must(
          esb
            .rangeQuery("date")
            .gt(dayjs().startOf("day").format("YYYY-MM-DD-HH:mm"))
            .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
            .format("yyyy-MM-dd-HH:mm")
            .timeZone(timeZone)
        )
    )

  const query = dailyRevenueQuery.toJSON() as Query
  const { data } = useQuery(SEARCH_ORDERS_TOTAL_HITS, {
    variables: { from: 0, size: 1000, query: JSON.stringify(query.query), trackTotalHits: true }
  })

  return (
    <CountCard
      onClick={() => navigate("/orders")}
      icon={OrderIcon}
      count={data?.searchOrders?.total}
      text="Orders today"
    />
  )
}
