import gql from "graphql-tag"

const GET_COMPENSATION = gql`
  query getCompensation($compensationId: ID!) {
    Order {
      compensation(compensationId: $compensationId) {
        id
        orderId
        started
        completed
        failed
        created
        updated
        restarts
        restarted
        reason {
          cause
          code
        }
        fee {
          amount
          name
          taxPercentage
          taxPercentageDecimals
        }
        payment {
          reference
        }
        giftCardProvider {
          providerName
          providerId
          status {
            current
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
          }
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
          }
        }
        giftCards {
          amount
          currencyCode
          giftCardId
          status
        }
        shippingFees {
          currencyCode
          shippingFeeId
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
        bonus {
          reservationId
          currencyCode
          amount
        }
        totals {
          giftCardTotal
          bonusTotal
          grandTotal
        }
        orderLines {
          currencyCode
          orderLineId
          quantity
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
      }
    }
  }
`

export default GET_COMPENSATION
