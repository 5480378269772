import React, { Dispatch, SetStateAction, useState } from "react"
import Popup from "../../../Ui/Popup"
import Reason from "./Reason"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentCancellation,
  InputActionType,
  InputReason,
  Order
} from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import CREATE_CANCELLATION from "../../../../graphql/mutations/order/cancellation/CreateCancellation"
import DELETE_CANCELLATION from "../../../../graphql/mutations/order/cancellation/DeleteCancellation"
import CancellationAction from "./CancellationAction"
import START_CANCELLATION from "../../../../graphql/mutations/order/cancellation/StartCancellation"
import StatusPopup from "./StatusPopup"
import { handleErrorMessages } from "helpers/errors"

type Props = {
  setShowCancelPopup: Dispatch<SetStateAction<boolean>>
  showCancelPopup: boolean
  order: Order
  refetch: () => void
}

const CancelOrder = ({ setShowCancelPopup, order, refetch, showCancelPopup }: Props) => {
  const [reason, setReason] = React.useState<InputReason>({ cause: "", code: "" })
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [giftCardAction, setGiftCardAction] = useState<InputActionGiftCard>({
    actionType: InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<InputActionBonus>({
    actionType: InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<InputActionPaymentCancellation>({
    actionType: InputActionType.Auto
  })
  const [reasonError, setReasonError] = useState<boolean>(false)

  const [createCancellation, { loading: createLoading }] = useMutation(CREATE_CANCELLATION, {
    onCompleted: () => {
      setReasonError(false)
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
      setReasonError(false)
    }
  })

  const [deleteCancellation, { loading: deleteLoading }] = useMutation(DELETE_CANCELLATION, {
    onCompleted: () => {
      setReasonError(false)
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
      setReasonError(false)
    }
  })

  const [startCancellation, { loading: startLoading }] = useMutation(START_CANCELLATION, {
    onCompleted: () => {
      setShowCancelPopup(false)
      setShowStatusPopup(true)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const createOrderCancellation = () => {
    if (
      (reason.code === "" && reason.cause === "") ||
      (reason.code !== "" && reason.cause !== "")
    ) {
      return createCancellation({
        variables: {
          orderId: order.id,
          input: { ...(reason.cause && reason.code && { reason: reason }) }
        }
      })
    }
    setReasonError(true)
  }

  const deleteOrderCancellation = () => {
    if (order.Cancellations[0]?.id)
      deleteCancellation({ variables: { cancellationId: order.Cancellations[0].id } })
  }

  const startOrderCancellation = () =>
    startCancellation({
      variables: {
        cancellationId: order.Cancellations[0].id,
        input: {
          ...(order.Cancellations[0]?.bonus && { bonus: bonusAction }),
          ...(order.Cancellations[0].giftCards.length > 0 && { giftCard: giftCardAction }),
          payment: paymentAction
        }
      }
    })

  if (showStatusPopup) {
    return (
      <StatusPopup
        setShowStatusPopup={setShowStatusPopup}
        cancellationId={order?.Cancellations[0].id}
        refetchOrder={refetch}
      />
    )
  }

  if (showCancelPopup) {
    return (
      <Popup
        title={`Cancel order`}
        handleCloseClick={() => {
          setReasonError(false)
          setShowCancelPopup(false)
          deleteOrderCancellation()
        }}
        type={order?.Cancellations?.length > 0 ? "delete" : "default"}
        buttonText={order?.Cancellations?.length > 0 ? "Confirm cancellation" : "Next"}
        handleOkClick={() =>
          order?.Cancellations?.length > 0 ? startOrderCancellation() : createOrderCancellation()
        }
        loading={createLoading || startLoading}
      >
        <Reason
          order={order}
          reason={reason}
          setReason={setReason}
          createLoading={createLoading}
          deleteLoading={deleteLoading}
          deleteCancellation={deleteOrderCancellation}
          reasonError={reasonError}
        />
        {order?.Cancellations?.length > 0 && (
          <CancellationAction
            cancellation={order?.Cancellations[0]}
            actionState={{
              giftCardAction,
              setGiftCardAction,
              bonusAction,
              setBonusAction,
              paymentAction,
              setPaymentAction
            }}
            orderId={order.id}
          />
        )}
      </Popup>
    )
  }
  return <></>
}

export default CancelOrder
