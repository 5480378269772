import React, { Dispatch, SetStateAction, useState } from "react"
import {
  InputPercentage,
  OrderPercentageContainer,
  PercentageButton,
  PercentageButtonWrapper
} from "../Shared.styled"

type Props = {
  percentage: number | undefined
  setPercentage: Dispatch<SetStateAction<number | undefined>>
  updateOrderLinesAmount: (percentage: number) => void
  customPercentage: string | undefined
  setCustomPercentage: Dispatch<SetStateAction<string | undefined>>
}

const percentageArray = [5, 10, 15, 20, 25]

const OrderPercentage = ({
  percentage,
  setPercentage,
  updateOrderLinesAmount,
  customPercentage,
  setCustomPercentage
}: Props) => {
  const [showInput, setShowInput] = useState(false)

  const handlePercentageClick = (percentage: number) => {
    setCustomPercentage("")
    setPercentage(percentage)
    updateOrderLinesAmount(percentage)
  }

  const updateCustomPercentage = (percentage: string) => {
    if (
      (parseInt(percentage) && parseInt(percentage) < 101 && parseInt(percentage) > 0) ||
      isNaN(parseInt(percentage))
    ) {
      setPercentage(parseInt(percentage))
      setCustomPercentage(percentage)
      updateOrderLinesAmount(parseInt(percentage))
    }
  }

  return (
    <OrderPercentageContainer>
      <b>Issue compensation for entire order</b>
      <PercentageButtonWrapper>
        {percentageArray.map((value) => (
          <PercentageButton
            key={value}
            $selected={value === percentage}
            onClick={() => handlePercentageClick(value)}
          >
            {value}%
          </PercentageButton>
        ))}
        {(showInput || !customPercentage) && (
          <InputPercentage
            name={"percentage"}
            onBlur={() => setShowInput(false)}
            onWheelCapture={(e) => e.currentTarget.blur()}
            onFocus={() => setShowInput(true)}
            type={"number"}
            value={customPercentage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateCustomPercentage(e.target.value)
            }
          />
        )}
        {!showInput && customPercentage && (
          <PercentageButton $selected={true} onClick={() => setShowInput(true)}>
            {customPercentage}%
          </PercentageButton>
        )}
      </PercentageButtonWrapper>
    </OrderPercentageContainer>
  )
}

export default OrderPercentage
