import React, { Dispatch, SetStateAction } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentRefund,
  InputActionType,
  OrderRefund
} from "lib/types/generated/graphql-types"
import {
  ActionWrapper,
  GiftCardInputWrapper,
  InfoWrapper,
  LabelWrapper,
  StyledActionDropdownButton
} from "./RefundAction.styled"
import { ActionDropdownButton } from "../../../../Ui/Buttons/ActionDropdownButton"
import Popup from "../../../../Ui/Popup"
import { ReactComponent as GiftCardIcon } from "../../../../../images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "../../../../../images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "../../../../../images/icons/credit-card.svg"
import Input from "../../../../Ui/Form/Input"
import { useMutation } from "@apollo/client"
import START_REFUND from "../../../../../graphql/mutations/order/refunds/StartRefund"
import { handleErrorMessages } from "helpers/errors"

type ActionState = {
  giftCardAction: InputActionGiftCard
  setGiftCardAction: Dispatch<SetStateAction<InputActionGiftCard>>
  bonusAction: InputActionBonus
  setBonusAction: Dispatch<SetStateAction<InputActionBonus>>
  paymentAction: InputActionPaymentRefund
  setPaymentAction: Dispatch<SetStateAction<InputActionPaymentRefund>>
}

type Props = {
  refund: OrderRefund
  actionState: ActionState
  setShowPopup: Dispatch<SetStateAction<boolean>>
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  orderId: string
  setStartedRefund: Dispatch<SetStateAction<string>>
}

const RefundAction = ({
  actionState,
  setShowPopup,
  refund,
  setShowStatusPopup,
  setStartedRefund
}: Props) => {
  const {
    giftCardAction,
    setGiftCardAction,
    bonusAction,
    setBonusAction,
    paymentAction,
    setPaymentAction
  } = actionState

  const [startRefund, { loading }] = useMutation(START_REFUND, {
    onCompleted: () => {
      setStartedRefund(refund.id)
      setShowPopup(false)
      setShowStatusPopup(true)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const startOrderRefund = () => {
    startRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...(refund?.bonus && { bonus: bonusAction }),
          ...(refund?.giftCards?.length > 0 && { giftCard: giftCardAction }),
          payment: paymentAction
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
                giftCardId: id,
                transactionId: value
              }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updatePaymentCaptureReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, refundReference: reference })
  }

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <Popup
      title={"How do you want to perform the action"}
      handleCloseClick={() => setShowPopup(false)}
      buttonText={"Finalize order"}
      handleOkClick={() => startOrderRefund()}
      loading={loading}
    >
      <InfoWrapper>
        <b>Auto</b> <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
        <b>Manual</b>{" "}
        <p>
          The action has been performed in an external system and will be stored as manually
          completed.
        </p>
        <b>Skip</b> <p>The action will be skipped entirely.</p>
      </InfoWrapper>
      <div>
        {refund?.giftCards?.length > 0 && (
          <div>
            <ActionWrapper>
              <LabelWrapper>
                <GiftCardIcon />
                <b>GiftCard - {refund?.giftCardProvider?.providerName}</b>
              </LabelWrapper>
              <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                <li onClick={() => setGiftCardAction({ actionType: InputActionType.Auto })}>
                  Auto
                </li>
                <li onClick={() => setGiftCardAction({ actionType: InputActionType.Manual })}>
                  Manual
                </li>
                <li onClick={() => setGiftCardAction({ actionType: InputActionType.Skip })}>
                  Skip
                </li>
              </ActionDropdownButton>
            </ActionWrapper>
            {giftCardAction.actionType === InputActionType.Manual && (
              <>
                {refund.giftCards.map((giftCard) => {
                  return (
                    <GiftCardInputWrapper key={giftCard.giftCardId}>
                      <p>{giftCard.giftCardId}</p>
                      <Input
                        removePaddingBottom={true}
                        name="transactionId"
                        placeholder="Transaction id"
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          updateGiftCardActionCards(giftCard.giftCardId, e.currentTarget.value)
                        }
                      />
                    </GiftCardInputWrapper>
                  )
                })}
              </>
            )}
          </div>
        )}
        {refund?.bonus && (
          <div>
            <ActionWrapper>
              <LabelWrapper>
                <BonusIcon />
                <b>Bonus - {refund?.bonusProvider?.providerName}</b>{" "}
              </LabelWrapper>
              <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                <li onClick={() => setBonusAction({ actionType: InputActionType.Auto })}>Auto</li>
                <li onClick={() => setBonusAction({ actionType: InputActionType.Manual })}>
                  Manual
                </li>
                <li onClick={() => setBonusAction({ actionType: InputActionType.Skip })}>Skip</li>
              </ActionDropdownButton>
            </ActionWrapper>
          </div>
        )}
        <ActionWrapper>
          <LabelWrapper>
            <PaymentIcon />
            <b>Payment - {refund?.paymentProvider?.providerName}</b>
          </LabelWrapper>
          <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
            <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>Auto</li>
            <li onClick={() => setPaymentAction({ actionType: InputActionType.Manual })}>Manual</li>
            <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>Skip</li>
          </StyledActionDropdownButton>
        </ActionWrapper>
        {paymentAction.actionType === InputActionType.Manual && (
          <Input
            name="captureReference"
            label="Capture reference"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updatePaymentCaptureReference(e.currentTarget.value)
            }
          />
        )}
      </div>
    </Popup>
  )
}

export default RefundAction
