import React, { useState } from "react"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { InputOrderCompensationFee, OrderCompensation } from "@lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { ReactComponent as TrashIcon } from "../../../../../images/icons/trash-can.svg"
import { Loader } from "../../../../Ui/Loader"
import { theme } from "../../../../../theme"
import {
  AddedReason,
  Container,
  Label,
  LabelWrapper,
  StyledSelect,
  TaxWrapper,
  Wrapper
} from "./CompensationFee.styled"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import Input from "../../../../Ui/Form/Input"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "images/icons/chevron-up.svg"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { getCompensationInput } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"

type Props = {
  compensation: OrderCompensation
  refetch: () => void
  currencyCode: string
}

const options = [
  {
    value: "2",
    label: "2"
  },
  {
    value: "3",
    label: "3"
  }
]

const CompensationFee = ({ compensation, refetch, currencyCode }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const {
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { isValid }
  } = useForm<InputOrderCompensationFee>({
    mode: "onBlur"
  })

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setExpanded(false)
      setValue("name", "")
      setValue("amount", 0)
      setValue("taxPercentage", 0)
      setValue("taxPercentageDecimals", 0)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const updateCompensationFee = (compensationFee: InputOrderCompensationFee) => {
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          payment: {amount: (compensation?.payment?.amount ?? 0) - compensationFee.amount},
          fee: { ...compensationFee }
        }
      }
    })
  }

  const removeCompensationFee = () => {
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          payment: {amount: (compensation?.payment?.amount ?? 0) + (compensation?.fee?.amount ?? 0)},
          fee: null
        }
      }
    })
  }

  const onSubmit: SubmitHandler<InputOrderCompensationFee> = (data) => {
    updateCompensationFee(data)
  }

  const form = useWatch({
    control
  })

  if (compensation?.fee)
    return (
      <Wrapper>
        <Label>Compensation Fee</Label>
        <AddedReason>
          <div>
            <span>
              <b>Name:</b> {compensation?.fee.name}
            </span>
            <span>
              <b>Amount:</b>{" "}
              <Money
                amount={compensation?.fee.amount}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </span>
          </div>
          {loading ? (
            <Loader color={theme.colors.grey} />
          ) : (
            <TrashIcon onClick={removeCompensationFee}></TrashIcon>
          )}
        </AddedReason>
      </Wrapper>
    )

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelWrapper onClick={() => setExpanded(!expanded)}>
          <Label>Compensation fee</Label>
          {expanded ? <UpIcon/> : <DownIcon />}
        </LabelWrapper>
        {expanded && (
          <Container>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Input
                  label="Amount in minor units"
                  removePaddingBottom={true}
                  type={"number"}
                  {...field}
                  onChange={(value: React.FormEvent<HTMLInputElement>) =>
                    field.onChange(value.currentTarget.valueAsNumber)
                  }
                />
              )}
              rules={{ required: true, min: 1 }}
            />
            <div>
              {" "}
              <strong>Amount formatted: </strong>
              <Money
                amount={getValues("amount") || 0}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </div>
            <Controller
              name="name"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field }) => <Input label="Name" removePaddingBottom={true} {...field} />}
            />
            <TaxWrapper>
              <Controller
                name="taxPercentage"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Tax percentage"
                    removePaddingBottom={true}
                    type={"number"}
                    {...field}
                    onChange={(value: React.FormEvent<HTMLInputElement>) =>
                      field.onChange(value.currentTarget.valueAsNumber)
                    }
                  />
                )}
                rules={{ required: true, min: 1 }}
              />
              <StyledSelect
                options={options}
                label="Tax percentage decimals"
                setValue={(e) => setValue("taxPercentageDecimals", parseInt(e))}
              />
            </TaxWrapper>
            <div>
              <strong>Tax formatted: </strong>
              {form.taxPercentage && form?.taxPercentageDecimals
                ? getValues("taxPercentage") / 10 ** getValues("taxPercentageDecimals") + "%"
                : "0"}
            </div>
            <PrimaryButton
              loading={loading}
              type="submit"
              disabled={
                !isValid ||
                !getValues("taxPercentageDecimals") ||
                !getValues("amount") ||
                !getValues("taxPercentage")
              }
            >
              Add compensation fee
            </PrimaryButton>
          </Container>
        )}
      </form>
    </Wrapper>
  )
}

export default CompensationFee
