import React, { Dispatch, SetStateAction, useState } from "react"
import * as graphql from "lib/types/generated/graphql-types"
import {
  ActionWrapper,
  ButtonWrapper,
  Container,
  InfoWrapper,
  LabelWrapper,
  Provider,
  Providers,
  ProviderWrapper,
  StyledActionDropdownButton,
  Title
} from "./FailedModifications.styled"
import { useMutation, useQuery } from "@apollo/client"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import CopyButton from "../../../Ui/CopyButton"
import Input from "../../../Ui/Form/Input"
import { ReactComponent as PaymentIcon } from "../../../../images/icons/credit-card.svg"
import ErrorMessages from "../ErrorMessages"
import { handleErrorMessages } from "helpers/errors"
import RESTART_MODIFICATION from "../../../../graphql/mutations/order/modification/RestartModification"
import GET_MODIFICATION from "../../../../graphql/queries/order/GetModification"

type Providers = {
  payment: boolean
}

type Props = {
  failedModification: graphql.OrderModification
  currencyCode: string
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  setStartedModification: Dispatch<SetStateAction<string>>
  orderHistory: graphql.OrderHistoryLogEntry[]
  refetch: () => void
}

enum FAILURE {
  PAYMENT = "MODIFY_ORDER_LINES_PAYMENT_FAILURE",
}

const FailedModification = ({
  failedModification,
  setShowStatusPopup,
  setStartedModification,
  orderHistory,
  refetch
}: Props) => {
  const [failedProviders, setFailedProviders] = useState<Providers | undefined>()
  const [paymentAction, setPaymentAction] = useState<graphql.InputActionPaymentDelivery>({
    actionType: graphql.InputActionType.Auto
  })

  const [restartModification, { loading }] = useMutation(RESTART_MODIFICATION, {
    onCompleted: () => {
      setShowStatusPopup(true)
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const restartOrderModification = () => {
    setStartedModification(failedModification.id)
    restartModification({
      variables: {
        modificationId: failedModification.id,
        input: {
          ...(failedProviders?.payment && { payment: paymentAction }),
        }
      }
    })
  }


  const updatePaymentCaptureReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, captureReference: reference })
  }

  useQuery(GET_MODIFICATION, {
    variables: { modificationId: failedModification.id },
    onCompleted: (data) => {
      const modificationDetails = data?.Order?.orderModificationOrderLines
      setFailedProviders({
        payment: modificationDetails?.paymentProvider?.status?.current === FAILURE.PAYMENT,
      })
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      <Container>
        <Title>
          <div>
            <h2>Failed Modification</h2>
          </div>
        </Title>
        id: {failedModification.id} <CopyButton string={failedModification.id} />
        <ProviderWrapper>
          <h4>Failed providers:</h4>
          {failedProviders?.payment && <Provider>Payment</Provider>}
        </ProviderWrapper>
        <>
          <InfoWrapper>
            <b>Auto</b>{" "}
            <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
            <b>Manual</b>{" "}
            <p>
              The action has been performed in an external system and will be stored as manually
              completed.
            </p>
            <b>Skip</b> <p>The action will be skipped entirely.</p>
          </InfoWrapper>
          <Providers>
            {failedProviders?.payment && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <PaymentIcon />
                    <b>Payment:</b>
                  </LabelWrapper>
                  <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Skip })}>
                      Skip
                    </li>
                  </StyledActionDropdownButton>
                </ActionWrapper>
                {paymentAction.actionType === graphql.InputActionType.Manual && (
                  <Input
                    name="captureReference"
                    label="Capture reference"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updatePaymentCaptureReference(e.currentTarget.value)
                    }
                  />
                )}
              </div>
            )}
          </Providers>
        </>
        <ErrorMessages
          orderHistory={orderHistory}
          operationId={failedModification.id}
          operation={"MODIFICATION"}
        />
        <ButtonWrapper>
          <SecondaryButton handleClick={restartOrderModification} loading={loading}>
            Restart modification
          </SecondaryButton>
        </ButtonWrapper>
      </Container>
    </>
  )
}

export default FailedModification
