import styled from "styled-components/macro"
import { Label, List } from "../../../../Ui/List/List"

export const Container = styled.div`
min-width: 35rem;
`

export const Summary = styled(List)`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  margin: 0 0 4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 3rem;
  }
`

export const DiscountRules = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  margin: 0 0 4rem;

`

export const DiscountRule = styled.div`
display: flex;
  width: 100%;
  justify-content: space-between;
`

export const StyledLabel = styled(Label)`
  width: 50%;
&:hover {
  cursor: pointer;
}
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`

export const AddOrderLineButton = styled.div`
  border: 0.1rem solid ${(props) => props.theme.colors.borderLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  border-radius: 0.6rem;
  color: ${(props) => props.theme.colors.greyDarker};
  background: ${p => p.theme.colors.white};

  svg {
    height: 3rem;
    fill: ${(props) => props.theme.colors.greyDarker};
    margin-bottom: 0.5rem;
  }
  &:hover {
  background: ${(props) => props.theme.colors.background};
    cursor: pointer;
  }
`


