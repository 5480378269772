import { v4 as uuidv4 } from "uuid"
import { Discount } from "lib/types"
import {
  Condition,
  DiscountExternalRule,
  DiscountRule,
  LogicHolder,
  Outcome
} from "@lib/types/generated/graphql-types"

export const getLogicData = (
  logicRule: LogicHolder,
  discountRule: DiscountRule | DiscountExternalRule
) => {
  const logicConditions = JSON.parse(logicRule.if)

  const andConditions = logicConditions?.and?.map((logicCondition: Discount.LogicCondition) =>
      discountRule.conditions.find(
        (condition: Condition) => logicCondition.validate === condition.id && condition
      )
    )
    .map((condition: Condition) => ({
      id: condition?.id,
      type: "condition",
      title: "Condition",
      data: {
        type: condition?.type,
        identifiers: condition?.identifiers,
        thresholds: condition?.thresholds,
        itemRule: condition?.itemRule,
        triggerQuantity: condition?.triggerQuantity
      }
    }))

  const outcomes = logicRule.do
    .map((logicOutcome: string) =>
      discountRule.outcomes.find((outcome: Outcome) => logicOutcome === outcome.id && outcome)
    )
    .map((outcome: Outcome | undefined) => ({
      id: outcome?.id,
      type: "outcome",
      title: "Outcome",
      data: {
        type: outcome?.type,
        discountValue: outcome?.discountValue,
        discountItemRule: outcome?.discountItemRule,
        triggerItemRule: outcome?.triggerItemRule,
        isRecurrent: outcome?.isRecurrent,
        triggerQuantity: outcome?.triggerQuantity,
        discountQuantity: outcome?.discountQuantity,
        gifts: outcome?.gifts,
        combineItemDiscount: outcome?.combineItemDiscount,
        subOutcomes: outcome?.subOutcomes,
        shippingTags: outcome?.shippingTags,
      }
    }))

  const outcomesWithOperators = []
  for (const type of outcomes) {
    outcomesWithOperators.push(type)
    if (outcomes.indexOf(type) !== outcomes.length - 1)
      outcomesWithOperators.push({
        content: "AND",
        title: "AND",
        type: "operator",
        id: uuidv4()
      })
  }

  return {
    [uuidv4()]: {
      conditions: andConditions?.flatMap((c: Discount.FrontendCondition, i: number) =>
        (i + 1) % 1 === 0 && i !== andConditions.length - 1
          ? [
              c,
              {
                content: "AND",
                title: "AND",
                type: "operator",
                id: uuidv4()
              }
            ]
          : c
      ),
      outcomes: outcomesWithOperators
    }
  }
}
