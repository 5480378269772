import {
  CompensationOrderLine,
  CompensationShippingFee,
  DeliveryOrderLine,
  OrderCompensation,
  OrderDelivery
} from "@lib/types/generated/graphql-types"
import {
  InputOrderLineCompensationWithAmount,
  InputShippingFeeCompensationWithAmount
} from "@lib/types/order"
import Dinero from "dinero.js"
import DineroFactory from "dinero.js"

export const compensationOrderLineOutputToInput = (orderLine: CompensationOrderLine) => ({
  orderLineId: orderLine.orderLineId,
  quantity: orderLine.quantity,
  taxPercentage: orderLine.taxPercentage,
  taxPercentageDecimals: orderLine.taxPercentageDecimals,
  totalAmount: orderLine.totalAmount,
  totalDiscountAmount: orderLine.totalDiscountAmount,
  totalTaxAmount: orderLine.totalTaxAmount
})

export const toCompensationShippingFeeFromState = (
  shippingFee: InputShippingFeeCompensationWithAmount | undefined,
  currencyCode: string
) => ({
  shippingFeeId: shippingFee?.shippingFeeId,
  taxPercentage: shippingFee?.taxPercentage,
  taxPercentageDecimals: shippingFee?.taxPercentageDecimals,
  totalAmount: shippingFee?.totalAmount,
  totalDiscountAmount:
    (shippingFee?.totalDiscountAmount ?? 0) + (shippingFee?.compensationAmount ?? 0),
  totalTaxAmount: calculateVAT(
    Dinero({
      amount:
        (shippingFee?.totalAmount ?? 0) -
        (shippingFee?.totalDiscountAmount ?? 0) +
        (shippingFee?.compensationAmount ?? 0),
      currency: currencyCode as DineroFactory.Currency
    }),
    shippingFee?.totalTaxAmount ?? 0,
    shippingFee?.taxPercentageDecimals ?? 0
  ).getAmount()
})

export const toCompensationOrderLineFromState = (
  orderLine: InputOrderLineCompensationWithAmount | undefined,
  currencyCode: string
) => ({
  orderLineId: orderLine?.orderLineId,
  quantity: orderLine?.quantity,
  taxPercentage: orderLine?.taxPercentage,
  taxPercentageDecimals: orderLine?.taxPercentageDecimals,
  totalAmount: orderLine?.totalAmount,
  totalDiscountAmount: (orderLine?.totalDiscountAmount ?? 0) + (orderLine?.compensationAmount ?? 0),
  totalTaxAmount: calculateVAT(
    Dinero({
      amount:
        (orderLine?.totalAmount ?? 0) -
        (orderLine?.totalDiscountAmount ?? 0) +
        (orderLine?.compensationAmount ?? 0),
      currency: currencyCode as DineroFactory.Currency
    }),
    orderLine?.totalTaxAmount ?? 0,
    orderLine?.taxPercentageDecimals ?? 0
  ).getAmount()
})

export const compensationShippingFeeOutputToInput = (fee: CompensationShippingFee) => ({
  shippingFeeId: fee.shippingFeeId,
  taxPercentage: fee.taxPercentage,
  taxPercentageDecimals: fee.taxPercentageDecimals,
  totalAmount: fee.totalAmount,
  totalDiscountAmount: fee.totalDiscountAmount,
  totalTaxAmount: fee.totalTaxAmount
})

export const compensationOrderLinesOutputToInput = (
  compensationOrderLines: CompensationOrderLine[]
) => compensationOrderLines?.map((ol) => compensationOrderLineOutputToInput(ol))

export const compensationShippingFeesOutputToInput = (shippingFees: CompensationShippingFee[]) =>
  shippingFees?.map((fee) => compensationShippingFeeOutputToInput(fee))

export const deliveryOrderLineOutputToCompensationInput = (
  compensationOrderLines: DeliveryOrderLine[]
) => {
  return compensationOrderLines?.map((ol) => ({
    orderLineId: ol.orderLineId,
    quantity: ol.quantity,
    taxPercentage: ol.taxPercentage,
    taxPercentageDecimals: ol.taxPercentageDecimals,
    totalAmount: ol.totalAmount,
    totalDiscountAmount: ol.totalDiscountAmount,
    totalTaxAmount: ol.totalTaxAmount
  }))
}

export const getDeliveryPaymentTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.grandTotal).reduce((a, b) => a + b, 0)

export const getDeliveryGiftCardTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.giftCardTotal).reduce((a, b) => a + b, 0)

export const getDeliveryBonusTotals = (deliveries: OrderDelivery[]) =>
  deliveries.map((delivery) => delivery.totals.bonusTotal).reduce((a, b) => a + b, 0)

export const getCompensationPaymentTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.grandTotal).reduce((a, b) => a + b, 0)

export const getCompensationGiftCardTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.giftCardTotal).reduce((a, b) => a + b, 0)

export const getCompensationBonusTotals = (compensations: OrderCompensation[]) =>
  compensations.map((compensation) => compensation.totals.bonusTotal).reduce((a, b) => a + b, 0)

export const getCompensationInput = (compensation: OrderCompensation | undefined) => ({
  ...(compensation?.payment && { payment: { amount: compensation.payment.amount } }),
  ...(compensation?.bonus && { bonus: { amount: compensation.bonus.amount } }),
  ...(compensation?.giftCards &&
    compensation?.giftCards?.length > 0 && { giftCards: compensation.giftCards }),
  ...(compensation?.orderLines && {
    orderLines: compensationOrderLinesOutputToInput(compensation?.orderLines)
  }),
  ...(compensation?.reason && { reason: compensation.reason }),
  ...(compensation?.shippingFees && {
    shippingFees: compensationShippingFeesOutputToInput(compensation.shippingFees)
  })
})

export const calculateVAT = (
  price: Dinero.Dinero,
  taxPercentage: number,
  taxPercentageDecimals: number
) => {
  const pow = Math.pow(10, taxPercentageDecimals + 2)
  const netAmount = price.divide(taxPercentage / pow + 1)
  return price.subtract(netAmount)
}
