import gql from "graphql-tag"

const GET_CART_RULE = gql`
  query getDiscountRule($id: ID!) {
    getDiscountRule(id: $id) {
      id
      name
      logicRules {
        if
        do
      }
      isActive
      isStackable
      sortOrder
      conditions {
        type
        id
        triggerQuantity
        identifiers
        thresholds {
          currencyCode
          units
        }
        itemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
      }
      outcomes {
        type
        id
        subOutcomes {
          discountValue {
            type
            percentage
            fixedDiscounts {
              currencyCode
              units
            }
          }
          triggerQuantity
          discountQuantity
        }
        discountValue {
          type
          percentage
          fixedDiscounts {
            currencyCode
            units
          }
        }
        discountItemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
        triggerItemRule {
          tagConditions {
            mode
            key
            values
          }
          excludeDiscountedPrices
          excludeFullPrices
        }
        triggerQuantity
        discountQuantity
        gifts {
          productVariantId
          quantity
        }
        shippingTags
        combineItemDiscount
        isRecurrent
      }
      validDateRange {
        from
        to
      }
    }
  }
`

export default GET_CART_RULE
