import React, { useState } from "react"
import { AddedReason, Container, Label, LabelWrapper, Wrapper } from "./Reason.styled"
import Input from "../../../../Ui/Form/Input"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import {
  InputReason,
  OrderLineModificationCreateData,
  OrderLineModificationType,
  OrderLineModificationUpdateData,
   OrderModificationOrderLines
} from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { ReactComponent as TrashIcon } from "../../../../../images/icons/trash-can.svg"
import { Loader } from "../../../../Ui/Loader"
import { theme } from "../../../../../theme"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { handleErrorMessages } from "helpers/errors"
import UPDATE_MODIFICATION from "../../../../../graphql/mutations/order/modification/UpdateModification"
import { createOutputToInput, updateOutputToInput } from "../Helpers"

type Props = {
  modification: OrderModificationOrderLines
  refetch: () => void
}

const Reason = ({ modification, refetch }: Props) => {
  const [reason, setReason] = React.useState<InputReason>({ cause: "", code: "" })
  const [expanded, setExpanded] = useState<boolean>(false)

  const updateCause = (cause: string) => setReason({ ...reason, cause: cause })
  const updateCode = (code: string) => setReason({ ...reason, code: code })

  const [updateModification, { loading }] = useMutation(UPDATE_MODIFICATION, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const updateReason = () => {
    updateModification({
      variables: {
        modificationId: modification.id,
        input: {
          orderLines: modification.orderLines.map((orderLine) => ({
                ...orderLine,
                data:
                  orderLine.modificationType === OrderLineModificationType.Update
                    ? JSON.stringify(
                      updateOutputToInput(orderLine.data as OrderLineModificationUpdateData)
                    )
                    : orderLine.modificationType === OrderLineModificationType.Create
                      ? JSON.stringify(
                        createOutputToInput(orderLine.data as OrderLineModificationCreateData)
                      )
                      : JSON.stringify(orderLine.data)
              })),
          ...(reason.cause && reason.code && { reason: reason })
        }
      }
    })
  }

  const removeReason = () => {
    updateModification({
      variables: {
        modificationId: modification.id,
        input: {
          orderLines: modification.orderLines.map((orderLine) => ({
            ...orderLine,
            data:
              orderLine.modificationType === OrderLineModificationType.Update
                ? JSON.stringify(
                  updateOutputToInput(orderLine.data as OrderLineModificationUpdateData)
                )
                : orderLine.modificationType === OrderLineModificationType.Create
                  ? JSON.stringify(
                    createOutputToInput(orderLine.data as OrderLineModificationCreateData)
                  )
                  : JSON.stringify(orderLine.data)
          })),
          reason: null
        }
      }
    })
  }

  if (modification?.reason)
    return (
      <Wrapper>
        <Label>Reason</Label>
        <AddedReason>
          <div>
            <span>
              <b>Cause:</b> {modification?.reason.cause}
            </span>
            <span>
              <b>Code:</b> {modification?.reason.code}
            </span>
          </div>
          {loading ? (
            <Loader color={theme.colors.grey} />
          ) : (
            <TrashIcon onClick={removeReason}></TrashIcon>
          )}
        </AddedReason>
      </Wrapper>
    )

  return (
    <Wrapper>
      <LabelWrapper onClick={() => setExpanded(!expanded)}>
        <Label>Reason</Label>
        <DownIcon />
      </LabelWrapper>
      {expanded && (
        <Container addedReason={!!modification?.reason}>
          {modification?.reason ? (
            <AddedReason>
              <div>
                <span>
                  <b>Cause:</b> {modification?.reason.cause}
                </span>
                <span>
                  <b>Code:</b> {modification?.reason.code}
                </span>
              </div>
              {loading ? (
                <Loader color={theme.colors.grey} />
              ) : (
                <TrashIcon onClick={removeReason}></TrashIcon>
              )}
            </AddedReason>
          ) : (
            <>
              <Input
                name={"cause"}
                placeholder={"Cause"}
                removePaddingBottom={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateCause(e.currentTarget.value)
                }
              />
              <Input
                name={"code"}
                placeholder={"Code"}
                removePaddingBottom={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateCode(e.currentTarget.value)
                }
              />
              <PrimaryButton
                disabled={!reason.cause || !reason.code}
                loading={loading}
                handleClick={updateReason}
              >
                Add
              </PrimaryButton>
            </>
          )}
        </Container>
      )}
    </Wrapper>
  )
}

export default Reason
