import React, { useState } from "react"
import {
  CompensationShippingFee,
  Order,
  OrderCompensation,
  ShippingFee
} from "lib/types/generated/graphql-types"
import { ItemIcon } from "./ShippingFeeItem.styled"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import Dinero from "dinero.js"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import {
  Amount,
  CartItemContainer,
  CartItemWrapper,
  DeleteButton,
  InfoWrapper,
  InputContainer,
  ItemDetails,
  ItemName,
  ItemPrice,
  Percentage,
  Prices
} from "./CartItem.styled"
import Input from "../../../../Ui/Form/Input"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import {
  calculateVAT,
  compensationShippingFeeOutputToInput,
  getCompensationInput
} from "../Helpers/helpers"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import { handleErrorMessages } from "helpers/errors"

type Props = {
  compensation: OrderCompensation
  compensationShippingFee: CompensationShippingFee
  orderShippingFee: ShippingFee | undefined
  currencyCode: string
  refetch: () => void
  order: Order
}

const ShippingFeeItem = ({
  compensation,
  currencyCode,
  compensationShippingFee,
  orderShippingFee,
  refetch
}: Props) => {
  const shippingFeeCompensationAmount =
    compensationShippingFee?.totalDiscountAmount - (orderShippingFee?.discountAmount ?? 0)

  const [amountInput, setAmountInput] = useState<number>(shippingFeeCompensationAmount)
  const [amountExpanded, setAmountExpanded] = useState(false)

  const shippingFees = compensation.shippingFees ?? []

  const getCompensationPercentage = () => {
    const percentage =
      (shippingFeeCompensationAmount /
        (compensationShippingFee.totalAmount)) *
      100
    return Number.isInteger(percentage) ? percentage : percentage.toFixed(1)
  }

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setAmountExpanded(false)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const updateAmount = () => {
    const difference = amountInput - shippingFeeCompensationAmount
    if (amountInput) {
      updateCompensation({
        variables: {
          compensationId: compensation.id,
          input: {
            ...getCompensationInput(compensation),
            payment: { amount: (compensation.payment?.amount ?? 0) + difference },
            shippingFees: [
              ...shippingFees.map((shippingFee) => ({
                ...compensationShippingFeeOutputToInput(shippingFee),
                ...(shippingFee?.shippingFeeId === compensationShippingFee.shippingFeeId && {
                  totalDiscountAmount: (orderShippingFee?.discountAmount ?? 0) + amountInput,
                  totalTaxAmount: calculateVAT(
                    Dinero({
                      amount:
                        (orderShippingFee?.basePriceAmount ?? 0) -
                        ((orderShippingFee?.discountAmount ?? 0) + amountInput),
                      currency: currencyCode as DineroFactory.Currency
                    }),
                    orderShippingFee?.taxPercentage ?? 0,
                    orderShippingFee?.taxPercentage ?? 0
                  ).getAmount()
                })
              }))
            ]
          }
        }
      })
    }
  }

  const removeShippingFee = () => {
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          payment: { amount: (compensation.payment?.amount ?? 0) - shippingFeeCompensationAmount },
          shippingFees: compensation?.shippingFees?.filter(
            (fee) => fee.shippingFeeId !== compensationShippingFee.shippingFeeId
          )
        }
      }
    })
  }

  return (
    <>
      <CartItemWrapper $loading={loading}>
        <CartItemContainer>
          <InfoWrapper>
            <ItemIcon>
              <ShippingIcon />
            </ItemIcon>
            <ItemDetails>
              <ItemName>{orderShippingFee?.name}</ItemName>
              <Prices>
                <ItemPrice>
                  <div>Total:</div>
                  <Money
                    amount={Number(
                      compensationShippingFee.totalAmount + (orderShippingFee?.discountAmount ?? 0)
                    )}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </ItemPrice>
                {orderShippingFee?.discountAmount && orderShippingFee?.discountAmount > 0 ? (
                  <ItemPrice>
                    <div>Discount:</div>-{" "}
                    <Money
                      amount={Number(orderShippingFee?.discountAmount)}
                      currencyUnit={currencyCode as DineroFactory.Currency}
                    />
                  </ItemPrice>
                ) : (
                  <></>
                )}
                {compensationShippingFee?.totalDiscountAmount &&
                compensationShippingFee?.totalDiscountAmount > 0 ? (
                  <ItemPrice>
                    <div>Compensation:</div>-{" "}
                    <Money
                      amount={Number(shippingFeeCompensationAmount)}
                      currencyUnit={currencyCode as DineroFactory.Currency}
                    />
                  </ItemPrice>
                ) : (
                  <></>
                )}
              </Prices>
              <Percentage>
                {Number.isInteger(getCompensationPercentage())
                  ? getCompensationPercentage()
                  : `~${getCompensationPercentage()}`}
                %
              </Percentage>
            </ItemDetails>
          </InfoWrapper>
          <DeleteButton onClick={removeShippingFee}>
            <DeleteIcon />
          </DeleteButton>
        </CartItemContainer>
        <Amount onClick={() => setAmountExpanded(!amountExpanded)}>
          Change amount {amountExpanded ? <ChevronUp /> : <ChevronDown />}
        </Amount>

        {amountExpanded && (
          <InputContainer>
            <div>
              <Input
                name={"compensationAmount"}
                label={"New compensation Amount"}
                type={"number"}
                defaultValue={shippingFeeCompensationAmount}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setAmountInput(e.currentTarget.value !== "" ? parseInt(e.currentTarget.value) : 0)
                }
              />
              {amountInput ? (
                <div>
                  <b>Formatted:</b>{" "}
                  <Money
                    amount={amountInput ?? 0}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <PrimaryButton
              handleClick={() => updateAmount()}
              disabled={amountInput === shippingFeeCompensationAmount}
              loading={loading}
            >
              Update
            </PrimaryButton>
          </InputContainer>
        )}
      </CartItemWrapper>
    </>
  )
}

export default ShippingFeeItem
