import React, { Dispatch, SetStateAction } from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import {
  Order,
  OrderLine,
  OrderLineModificationDeleteData,
  OrderLineModificationType,
  OrderModificationOrderLines,
  ProductVariant
} from "lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"
import { ReactComponent as PlusIcon } from "images/icons/circle-plus.svg"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { AddOrderLineButton, Blur, Container, DiscountRule, DiscountRules, StyledLabel, Summary } from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import { VariantRow } from "./VariantRow"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { VariantRowDeleted } from "./VariantRowDeleted"
import { useNavigate } from "react-router-dom"

enum TAX_GROUP {
QLIRO_INVOICE_FEE = "QliroFee"
}

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
  editOrderLine: OrderLine | undefined
  setEditOrderLine: Dispatch<SetStateAction<OrderLine | undefined>>
  setProductVariant: Dispatch<SetStateAction<ProductVariant | undefined>>
  notCompletedModifications: OrderModificationOrderLines[]
}

export const OrderSummary = ({
  order,
  refetch,
  setEditOrderLine,
  setProductVariant,
  notCompletedModifications
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null
  return (
    <Container>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Item>
        {notCompletedModifications[0]?.oldTotals?.grandTotal ? (
          <Item>
            <Label>Original order value:</Label>
            <Value>
              <Money
                amount={parseInt(`${notCompletedModifications[0]?.oldTotals?.grandTotal}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Item>
        ) : (
          <></>
        )}
        <Item>
          <Label>Total order value:</Label>
          <Value>
            <Money
              amount={parseInt(`${order?.totals?.grandTotal ?? 0}`)}
              currencyUnit={order?.currencyCode as DineroFactory.Currency}
            />
          </Value>
        </Item>
      </Summary>
      {((order?.discountRules && order?.discountRules?.length > 0) ||
        (order?.discountCodeRules && order?.discountCodeRules?.length > 0)) && (
        <>
          <h2>Discount rules ({(order?.discountRules?.length ?? 0) + (order?.discountCodeRules?.length ?? 0)})</h2>
          <DiscountRules>
            {order?.discountRules &&
              order?.discountRules?.length > 0 &&
              order.discountRules?.map((rule) => (
                <DiscountRule key={rule.id}>
                  <StyledLabel handleOnClick={() => navigate(`/discounts/cart-rules/${rule.id}`)}>{rule.name}</StyledLabel>
                    <Money
                      amount={parseInt(`${rule.discountAmount ?? 0}`)}
                      currencyUnit={order?.currencyCode as DineroFactory.Currency}
                    />
                </DiscountRule>
              ))}
            {order.discountCodeRules?.map((rule) => (
              <DiscountRule key={rule.id}>
                <StyledLabel handleOnClick={() => navigate(`/discounts/discount-code-rules/${rule.id}`)}>{rule.name}</StyledLabel>
                  <Money
                    amount={parseInt(`${rule.discountAmount ?? 0}`)}
                    currencyUnit={order?.currencyCode as DineroFactory.Currency}
                  />
              </DiscountRule>
            ))}
          </DiscountRules>
        </>
      )}

      <>
        <h2>Order lines ({order.orderLines?.length ?? 0})</h2>
        {order.orderLines?.filter(orderLine => orderLine.taxGroupId !== TAX_GROUP.QLIRO_INVOICE_FEE).map((orderLine) => (
          <VariantRow
            key={orderLine?.id}
            orderLine={orderLine}
            order={order}
            refetch={refetch}
            currencyCode={order?.currencyCode}
            setEditOrderLine={setEditOrderLine}
            setProductVariant={setProductVariant}
            notCompletedModifications={notCompletedModifications}
          />
        ))}
        {notCompletedModifications[0]?.orderLines.map((line) => (
          <>
            {line.modificationType === OrderLineModificationType.Delete && (
              <VariantRowDeleted orderLine={line.data as OrderLineModificationDeleteData} />
            )}
          </>
        ))}
        <AddOrderLineButton onClick={() => dispatch(showEditSidebar())}>
          <PlusIcon />
          Add order line
        </AddOrderLineButton>
      </>
    </Container>
  )
}
