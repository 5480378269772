import gql from "graphql-tag"

export const VALIDATE_AVARDA = gql`
  query validateAvarda($baseUrl: String!, $authUrl: String!, $clientId: String!, $clientSecret: String!) {
    Configuration {
      validate {
        avarda(baseUrl: $baseUrl, authUrl: $authUrl, clientId: $clientId, clientSecret: $clientSecret) {
          errorMessage
          valid
        }
      }
    }
  }
`
