import { InputConditionType, InputDiscountValueType, InputOutcomeType } from "lib/types/generated/graphql-types"

export const getLogicTypeDisplayName = (type: InputOutcomeType | InputConditionType | InputDiscountValueType) => {
  switch (type) {
    case InputConditionType.Cartoverthreshold:
      return "Cart over threshold"

    case InputConditionType.Cartunderthreshold:
      return "Cart under threshold"

    case InputConditionType.Minitemsvalue:
      return "Minimum cart items value"

    case InputConditionType.Minitems:
      return "Minimum cart items"

    case InputConditionType.Storegroup:
      return "Store group"

    case InputConditionType.Countrycode:
      return "Country code"

    case InputOutcomeType.Cartdiscount:
      return "Cart discount"

    case InputOutcomeType.Itemdiscounts:
      return "Item discounts"

    case InputOutcomeType.Buyget:
      return "Buy X get X"

    case InputOutcomeType.Buyselect:
      return "Buy X get discount on Y"

    case InputOutcomeType.Freeshipping:
      return "Free shipping"

    case InputOutcomeType.Gift:
      return "Gift with purchase (GWP)"

    case InputDiscountValueType.Fixed:
      return "Fixed amount"

    case InputDiscountValueType.Relative:
      return "Percentage"

    case InputOutcomeType.Selectitems:
      return "Item discount with specified quantity"

    case InputOutcomeType.Optimizebuygets:
      return "Buy X get X, with suboutcomes"

    case InputOutcomeType.Shippingtags:
      return "Shipping tags"

    default:
      return ""
  }
}
