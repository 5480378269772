import React, { useState } from "react"

import DineroFactory from "dinero.js"

import {
  Amount,
  CartItemContainer,
  CartItemWrapper,
  DeleteButton,
  InfoWrapper,
  InputContainer,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  Percentage,
  Prices
} from "./CartItem.styled"
import Money from "../../../../Money"

import placeholder from "../../../../../images/placeholder.svg"
import {
  CompensationOrderLine,
  OrderCompensation,
  OrderLine
} from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import {
  calculateVAT,
  compensationOrderLineOutputToInput,
  getCompensationInput
} from "../Helpers/helpers"
import Input from "../../../../Ui/Form/Input"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import { handleErrorMessages } from "helpers/errors"
import Dinero from "dinero.js"

type Props = {
  item: CompensationOrderLine
  currencyCode?: string
  orderLine: OrderLine | undefined
  compensation: OrderCompensation
  refetch: () => void
}

const CartItem = ({ item, currencyCode, orderLine, compensation, refetch, ...props }: Props) => {
  const orderLineCompensationAmount =
    item?.totalDiscountAmount - (orderLine?.distributedTotalDiscountAmount ?? 0)

  const [amountInput, setAmountInput] = useState<number>(orderLineCompensationAmount)
  const [amountExpanded, setAmountExpanded] = useState(false)

  const orderLines = compensation.orderLines ?? []

  const getCompensationPercentage = () => {
    const percentage =
      (orderLineCompensationAmount /
        (item.totalAmount)) *
      100
    return Number.isInteger(percentage) ? percentage : percentage.toFixed(1)
  }

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setAmountExpanded(false)
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const updateAmount = () => {
    const difference = amountInput - orderLineCompensationAmount
    if (amountInput) {
      updateCompensation({
        variables: {
          compensationId: compensation.id,
          input: {
            ...getCompensationInput(compensation),
            payment: { amount: (compensation.payment?.amount ?? 0) + difference },
            orderLines: [
              ...orderLines.map((ol) => ({
                ...compensationOrderLineOutputToInput(ol),
                ...(ol?.orderLineId === item.orderLineId && {
                  totalDiscountAmount:
                    (orderLine?.distributedTotalDiscountAmount ?? 0) + amountInput,
                  totalTaxAmount: calculateVAT(
                    Dinero({amount: (orderLine?.totalPriceAmount ?? 0) -
                        ((orderLine?.totalDiscountAmount ?? 0) + amountInput), currency: currencyCode as DineroFactory.Currency}),
                    orderLine?.taxPercentage ?? 0,
                    orderLine?.taxPercentage ?? 0
                  ).getAmount()
                })
              }))
            ]
          }
        }
      })
    }
  }

  const removeOrderLine = () => {
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          payment: { amount: (compensation.payment?.amount ?? 0) - orderLineCompensationAmount },
          orderLines: [
            ...orderLines
              .filter((ol) => item.orderLineId !== ol.orderLineId)
              .map((ol) => compensationOrderLineOutputToInput(ol))
          ]
        }
      }
    })
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <>
      <CartItemWrapper $loading={loading}>
        <CartItemContainer {...props}>
          <InfoWrapper>
            <ItemImage>
              <img src={orderLine?.imageUrl ?? ""} onError={handleImageError} />
            </ItemImage>
            <ItemDetails>
              <ItemName>{orderLine?.name}</ItemName>
              <Prices>
                <ItemPrice>
                  <div>Total:</div>
                  <Money
                    amount={Number(
                      item.totalAmount + (orderLine?.distributedTotalDiscountAmount ?? 0)
                    )}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </ItemPrice>
                {orderLine?.distributedTotalPriceAmount &&
                orderLine?.distributedTotalDiscountAmount > 0 ? (
                  <ItemPrice>
                    <div>Discount:</div>-{" "}
                    <Money
                      amount={Number(orderLine?.distributedTotalDiscountAmount)}
                      currencyUnit={currencyCode as DineroFactory.Currency}
                    />
                  </ItemPrice>
                ) : (
                  <></>
                )}
                {item?.totalDiscountAmount && item?.totalDiscountAmount > 0 ? (
                  <ItemPrice>
                    <div>Compensation:</div>-{" "}
                    <Money
                      amount={Number(orderLineCompensationAmount)}
                      currencyUnit={currencyCode as DineroFactory.Currency}
                    />
                  </ItemPrice>
                ) : (
                  <></>
                )}
              </Prices>
              <Percentage>
                {Number.isInteger(getCompensationPercentage())
                  ? getCompensationPercentage()
                  : `~${getCompensationPercentage()}`}
                %
              </Percentage>
            </ItemDetails>
          </InfoWrapper>
          <DeleteButton onClick={removeOrderLine}>
            <DeleteIcon />
          </DeleteButton>
        </CartItemContainer>
        <Amount onClick={() => setAmountExpanded(!amountExpanded)}>
          Change amount {amountExpanded ? <ChevronUp /> : <ChevronDown />}
        </Amount>

        {amountExpanded && (
          <InputContainer>
            <div>
              <Input
                name={"compensationAmount"}
                label={"New compensation Amount"}
                type={"number"}
                defaultValue={orderLineCompensationAmount}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setAmountInput(e.currentTarget.value !== "" ? parseInt(e.currentTarget.value) : 0)
                }
              />
              {amountInput ? (
                <div>
                  <b>Formatted:</b>{" "}
                  <Money
                    amount={amountInput ?? 0}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <PrimaryButton
              handleClick={() => updateAmount()}
              disabled={amountInput === orderLineCompensationAmount}
              loading={loading}
            >
              Update
            </PrimaryButton>
          </InputContainer>
        )}
      </CartItemWrapper>
    </>
  )
}

export default CartItem
