import React, { useState } from "react"

import DineroFactory from "dinero.js"

import {
  ActionWrapper,
  CartItemContainer,
  InfoWrapper,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  QtySelector
} from "./CartItem.styled"
import Money from "../../../../Money"

import placeholder from "../../../../../images/placeholder.svg"
import { OrderLine, OrderRefund, RefundOrderLine } from "lib/types/generated/graphql-types"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { ButtonWrapper } from "../Shared.styled"
import { useMutation } from "@apollo/client"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { getRefundInput } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"

type Props = {
  item: RefundOrderLine
  currencyCode?: string
  orderLine: OrderLine | undefined
  refund: OrderRefund
  refetch: () => void
}

const CartItem = ({ item, currencyCode, orderLine, refund, refetch, ...props }: Props) => {
  const [quantity, setQuantity] = useState(item.quantity)

  const [updateRefund, { loading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const onUpdate = (qty: number) => {
    if (qty === 0) {
      return updateRefund({
        variables: {
          refundId: refund.id,
          input: {
            ...getRefundInput(refund),
            orderLines: refund.orderLines
              .filter((ol) => ol.orderLineId !== item.orderLineId)
              .map((ol) => ({ orderLineId: ol.orderLineId, quantity: ol.quantity }))
          }
        }
      })
    }
    return updateRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...getRefundInput(refund),
          orderLines: [
            ...refund.orderLines.map((ol) => ({
              orderLineId: ol.orderLineId,
              quantity: ol.orderLineId === item.orderLineId ? qty : ol.quantity
            }))
          ]
        }
      }
    })
  }

  const updateQuantity = (mod: number) => {
    const qty = Math.max(quantity + mod, 0)
    setQuantity(qty)
    onUpdate(qty)
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <CartItemContainer {...props}>
      <InfoWrapper>
        <ItemImage>
          <img src={orderLine?.imageUrl ?? ""} onError={handleImageError} />
        </ItemImage>
        <ItemDetails>
          <ItemName>{orderLine?.name}</ItemName>
          <ItemPrice>
            <Money
              amount={Number(orderLine?.basePriceAmount) - (orderLine?.distributedTotalDiscountAmount ?? 0)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
            {item?.totalDiscountAmount && (
              <span>
                <Money
                  amount={Number(item?.totalAmount + item.totalDiscountAmount)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </span>
            )}
          </ItemPrice>
        </ItemDetails>
      </InfoWrapper>

      <ActionWrapper $loading={loading}>
        <span>
          <b>Action:</b>{" "}
          {orderLine?.quantity === item.quantity ? "Will be refunded" : "Will be refunded in part"}
        </span>
        <span>
          <b>Items to refund:</b>
          <QtySelector>
            <span onClick={() => updateQuantity(-1)}>-</span>
            <span>{item.quantity}</span>
            {
              <span
                onClick={() => {
                  if (orderLine?.quantity !== quantity) updateQuantity(+1)
                }}
              >
                +
              </span>
            }
          </QtySelector>
        </span>
        <span>
          <b>Amount to be refunded:</b>{" "}
          <Money amount={item.totalAmount} currencyUnit={currencyCode as DineroFactory.Currency} />
        </span>
        <hr />
        <ButtonWrapper>
          <SecondaryButton handleClick={() => onUpdate(0)}>Undo</SecondaryButton>
        </ButtonWrapper>
      </ActionWrapper>
    </CartItemContainer>
  )
}

export default CartItem
