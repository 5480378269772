import styled from "styled-components"

export const InputWrapper = styled.div`
display: flex;

  button {
    margin-left: 1rem;
    height: 4.4rem;
  }
`

export const AddedTag = styled.div`
  display: flex;
  font-weight: bold;
  margin: 0 1rem 1rem 0;
  border-radius: 0.6rem;
  border: 0.3rem solid ${ p  => p.theme.colors.turquoiseDark};
  background: ${p => p.theme.colors.turquoiseDark};

  span {
    border-radius: 0.3rem 0 0 0.3rem;
    width: fit-content;
    padding: 0 2rem 0 1.5rem;

    background: ${p => p.theme.colors.turquoiseDark};
    color: ${p => p.theme.colors.white};
    display: flex;
    align-items: center;
    svg {
      fill: ${p => p.theme.colors.white};
      height: 1.5rem;
      margin-right: 1rem;
    }
  }

`

export const RemoveTag = styled.div`
background: ${p => p.theme.colors.background};
  width: 3rem;
  border-radius: 0.4rem ;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 1.3rem;
    fill: ${p => p.theme.colors.greyDarker};
  }
  &:hover {
    background: ${p => p.theme.colors.greyLight};
    cursor: pointer;
  }
`

export const Tags = styled.div`
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`
