import React from "react"
import { Order, OrderDelivery, OrderGift } from "@lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Id, Name, ProductImage, Row } from "../Shared.styled"

import placeholder from "../../../../../images/placeholder.svg"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import CREATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/CreateDelivery"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import Status from "../../../../Ui/Status"
import { ReactComponent as DeliverIcon } from "../../../../../images/icons/truck-ramp-box.svg"
import { handleErrorMessages } from "helpers/errors"
import { getDeliveryInput } from "../Helpers/helpers"

type Props = {
  gift: OrderGift
  order: Order
  currencyCode: string
  deliveries: OrderDelivery[]
  refetch: () => void
}

export const GiftRow = ({ gift, order, currencyCode, deliveries, refetch }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const notCompletedDelivery = deliveries.find((delivery) => !delivery.started)
  const completedDeliveries = deliveries.filter((delivery) => !!delivery.completed)
  const giftsDelivered = completedDeliveries.map((delivery) =>
    delivery.gifts.find((deliveryGift) => deliveryGift.giftId === gift.id)
  )

  const getDeliveredQuantity = () =>
    giftsDelivered.reduce((a, b) => {
      return a + (b?.quantity ?? 0)
    }, 0)

  const getOrderGift = () => {
    return order?.gifts?.find((ol) => ol?.id === gift?.id)
  }

  const [createDelivery, { loading: createLoading }] = useMutation(CREATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [updateDelivery, { loading: updateLoading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const giftLineExistInDelivery = (id: string) => {
    return !!notCompletedDelivery?.gifts?.find((gift) => gift.giftId === id)
  }

  const createOrUpdateOrderDelivery = () => {
    if (!notCompletedDelivery) {
      return createDelivery({
        variables: {
          orderId: order.id,
          input: { gifts: { giftId: gift.id, quantity: gift.quantity - getDeliveredQuantity() } }
        }
      })
    }
    return updateDelivery({
      variables: {
        deliveryId: notCompletedDelivery.id,
        input: {
          ...getDeliveryInput(notCompletedDelivery),
          gifts: [
            ...notCompletedDelivery.gifts.map((gift) => ({
              giftId: gift.giftId,
              quantity: gift.quantity
            })),
            { giftId: gift.id, quantity: gift.quantity - getDeliveredQuantity() }
          ]
        }
      }
    })
  }

  return (
    <Row key={gift.id}>
      <ProductImage src={getOrderGift()?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{getOrderGift()?.name}</Name>
        <Money
          amount={getOrderGift()?.totalPriceAmount ?? 0}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
        <div>Order quantity: {gift?.quantity}</div>
        {getDeliveredQuantity() > 0 && <div>Delivered quantity: {getDeliveredQuantity()}</div>}
        <Id>
          <b>Variant ID:</b> <span>{getOrderGift()?.productVariantId}</span>
        </Id>
      </div>
      <DeliveryButtonWrapper>
        {getDeliveredQuantity() === gift?.quantity ? (
          <Status status="Delivered" />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderDelivery}
            disabled={!!giftLineExistInDelivery(gift.id)}
            loading={createLoading || updateLoading}
          >
            <DeliverIcon />
            Deliver
          </SecondaryButton>
        )}
        <span>
          Total :
          <b>
            <Money
              amount={getOrderGift()?.totalPriceAmount ?? 0 * gift.quantity}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
