import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { DeliveryShippingFee, Order } from "lib/types/generated/graphql-types"
import {
  Amount,
  AmountInput,
  Compensated,
  InputPercentage,
  Name,
  PercentageButton,
  PercentageButtonWrapper,
  RowContainer,
  RowWrapper
} from "../Shared.styled"

import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as ShippingIcon } from "../../../../../images/icons/cart-flatbed-boxes.svg"
import Input from "../../../../Ui/Form/Input"
import { InputShippingFeeCompensationWithAmount } from "@lib/types/order"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { compensationShippingFeesOutputToInput, getCompensationInput, toCompensationShippingFeeFromState } from "../Helpers/helpers"
import { handleErrorMessages } from "helpers/errors"
import { NoticeBanner } from "../../../../Ui/Messages/NoticeBanner"

type Props = {
  shippingFee: DeliveryShippingFee
  order: Order
  currencyCode: string
  setInputShippingFees: Dispatch<SetStateAction<InputShippingFeeCompensationWithAmount[]>>
  inputShippingFees: InputShippingFeeCompensationWithAmount[]
  refetch: () => void
  clearOrderPercentage: () => void
  orderPercentage: number | undefined
}

const percentageArray = [5, 10, 15, 20, 25]

export const ShippingRow = ({
  shippingFee,
  currencyCode,
  order,
  setInputShippingFees,
  inputShippingFees,
  refetch,
  clearOrderPercentage,
  orderPercentage
}: Props) => {
  const [percentage, setPercentage] = useState<number>()
  const [customPercentage, setCustomPercentage] = useState<string>()
  const [showInput, setShowInput] = useState(false)

  const notCompletedCompensation = order.Compensations.find(
    (compensation) => !compensation.started || (compensation.started && !compensation.completed)
  )

  const orderShippingFee = order?.shippingFees?.find((fee) => fee.id === shippingFee.shippingFeeId)

  const completedCompensations = order.Compensations.filter(
    (compensation) => !!compensation.completed
  )
  const shippingFeeCompensated = completedCompensations
    .map((compensation) =>
      compensation?.shippingFees?.find((fee) => fee.shippingFeeId === shippingFee.shippingFeeId)
    )
    .filter((f) => !!f)

  const getShippingFee = () => {
    return order?.shippingFees?.find((fee) => fee.id === shippingFee.shippingFeeId)
  }

  const getCompensatedAmount = () =>
    shippingFeeCompensated.reduce(
      (a, b) => a + (b.totalDiscountAmount - (getShippingFee()?.discountAmount ?? 0)),
      0
    )

  const getAmount = () =>
    inputShippingFees.find(
      (shippingFeeAmount) => shippingFeeAmount.shippingFeeId === shippingFee.shippingFeeId
    )?.compensationAmount

  const setAmount = (amount: number) => {
    setInputShippingFees(
      inputShippingFees.map((fee) =>
        fee.shippingFeeId === shippingFee.shippingFeeId
          ? {
              ...fee,
              compensationAmount: amount
            }
          : fee
      )
    )
  }

  const handlePercentageClick = (percentage: number) => {
    setCustomPercentage("")
    setPercentage(percentage)
    setAmount(Math.floor(shippingFee.totalAmount * (percentage / 100)))
    clearOrderPercentage()
  }

  const updateAmount = (value: number) => {
    setCustomPercentage("")
    setPercentage(undefined)
    setAmount(value)
    clearOrderPercentage()
  }

  const updateCustomPercentage = (percentage: string) => {
    if (
      (parseInt(percentage) && parseInt(percentage) < 101 && parseInt(percentage) > 0) ||
      isNaN(parseInt(percentage))
    ) {
      setPercentage(undefined)
      setCustomPercentage(percentage)
      setAmount(Math.floor(shippingFee.totalAmount * (parseInt(percentage) / 100)))
      clearOrderPercentage()
    }
  }

  const [updateCompensation, { loading: updateLoading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const updateOrderCompensation = () => {
    updateCompensation({
      variables: {
        compensationId: notCompletedCompensation?.id,
        input: {
          ...getCompensationInput(notCompletedCompensation),
          payment: {
            amount: (notCompletedCompensation?.payment?.amount ?? 0) + (getAmount() ?? 0)
          },
          shippingFees: [
            ...compensationShippingFeesOutputToInput(notCompletedCompensation?.shippingFees ?? []),
            toCompensationShippingFeeFromState(
              inputShippingFees.find(
                (inputShippingFee) => inputShippingFee.shippingFeeId === shippingFee.shippingFeeId
              ),
              order.currencyCode
            )
          ]
        }
      }
    })
    setInputShippingFees(
      inputShippingFees.map((shippingFee) => ({ ...shippingFee, compensationAmount: 0 }))
    )
  }

  useEffect(() => {
    if (orderPercentage) {
      if (percentageArray.includes(orderPercentage)) {
        setPercentage(orderPercentage)
        setCustomPercentage("")
        return
      }
      setPercentage(undefined)
      setCustomPercentage(orderPercentage.toString())
    }
  }, [orderPercentage])

  if (shippingFee.totalAmount === 0) return null

  return (
    <RowContainer>
      {getCompensatedAmount() > 0 && (
        <>
          <Compensated>
            Previously compensated amount:{" "}
            <Money
              amount={getCompensatedAmount()}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </Compensated>
          <hr />
        </>
      )}
      <RowWrapper key={shippingFee.shippingFeeId}>
        <div>
          <ShippingIcon />
          <div>
            <Name>{orderShippingFee?.name}</Name>
            <Money
              amount={shippingFee.totalAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            ></Money>
          </div>
        </div>
        <span>
          <Amount>
            <b>Compensation</b>
            <PercentageButtonWrapper>
              {percentageArray.map((value) => (
                <PercentageButton
                  key={value}
                  $selected={value === percentage}
                  $orderPercentage={!!orderPercentage}
                  onClick={() => handlePercentageClick(value)}
                >
                  {value}%
                </PercentageButton>
              ))}
              {(showInput || !customPercentage) && (
                <InputPercentage
                  name={"percentage"}
                  onBlur={() => setShowInput(false)}
                  onFocus={() => setShowInput(true)}
                  onWheelCapture={(e) => e.currentTarget.blur()}
                  type={"number"}
                  value={customPercentage}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateCustomPercentage(e.target.value)
                  }
                />
              )}
              {!showInput && customPercentage && (
                <PercentageButton
                  $selected={true}
                  $orderPercentage={!!orderPercentage}
                  onClick={() => setShowInput(true)}
                >
                  {customPercentage}%
                </PercentageButton>
              )}
            </PercentageButtonWrapper>
            <AmountInput>
              <Input
                name={"amount"}
                value={getAmount()}
                type="number"
                placeholder={"Amount"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateAmount(parseInt(e.target.value))
                }
              />
              <div>
                <b>Formatted:</b>{" "}
                {getAmount() ? (
                  <Money
                    amount={getAmount() ?? 0}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                ) : (
                  0
                )}
              </div>
            </AmountInput>
          </Amount>
        </span>
      </RowWrapper>
      {(getAmount() ?? 0) > shippingFee.totalAmount && (
        <NoticeBanner type={"important"}>Amount to high</NoticeBanner>
      )}
      {notCompletedCompensation && (
        <PrimaryButton
          handleClick={updateOrderCompensation}
          loading={updateLoading}
          disabled={!getAmount() || getAmount() === 0}
        >
          Add shipping fee
        </PrimaryButton>
      )}
    </RowContainer>
  )
}
