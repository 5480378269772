import React from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import {
  Order,
  OrderGiftCardProduct,
  OrderGiftCardProductRetain24
} from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { Blur, Container, Summary } from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import CREATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/CreateDelivery"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { VariantRow } from "./VariantRow"
import { GiftRow } from "./GiftRow"
import { ShippingRow } from "./ShippingRow"
import { handleErrorMessages } from "helpers/errors"
import { GiftCardProductRow } from "./GiftCardProductRow"

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
}

export const OrderSummary = ({ order, refetch }: Props) => {
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  function isOrderGiftCardProductRetain24(
    object: OrderGiftCardProduct
  ): object is OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  const completedDeliveries = order?.Deliveries.filter((delivery) => !!delivery.completed)
  const notCompletedDeliveries = order?.Deliveries.filter((delivery) => !delivery.completed)

  const [createDelivery, { loading: createLoading }] = useMutation(CREATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const createOrderDelivery = () => {
    return createDelivery({
      variables: {
        orderId: order.id,
        input: {
          orderLines: order?.orderLines?.map((orderLine) => ({
            orderLineId: orderLine.id,
            quantity: orderLine.quantity
          })),
          ...(order.gifts &&
            order.gifts.length > 0 && {
              gifts: order.gifts.map((gift) => ({ giftId: gift.id, quantity: gift.quantity }))
            }),
          shippingFees: order?.shippingFees?.map((fee) => fee.id),
          ...(isOrderGiftCardProductRetain24(order.GiftCardProduct) &&
            order?.GiftCardProduct?.giftCardProducts && {
              giftCardProducts: order?.GiftCardProduct?.giftCardProducts.map((giftCardProduct) => ({
                giftCardProductId: giftCardProduct.id
              }))
            })
        }
      }
    })
  }

  const orderLinesWithoutCompletedDeliveries = order?.orderLines?.filter((ol) =>
    order.Deliveries.map((delivery) => delivery.orderLines.map((dol) => dol.orderLineId !== ol.id))
  )

  const shippingFeesWithoutCompletedDeliveries = order?.shippingFees?.filter((orderFee) =>
    order.Deliveries.map((delivery) =>
      delivery.shippingFees.map((deliveryFee) => orderFee.id !== deliveryFee.shippingFeeId)
    )
  )

  const giftCardsWithoutCompletedDeliveries = isOrderGiftCardProductRetain24(order?.GiftCardProduct)
    ? order?.GiftCardProduct?.giftCardProducts?.filter((orderFee) =>
        order.Deliveries.map((delivery) =>
          delivery.shippingFees.map((deliveryFee) => orderFee.id !== deliveryFee.shippingFeeId)
        )
      )
    : []

  const giftsWithoutCompletedDeliveries = order?.gifts?.filter((gift) =>
    order.Deliveries.map((delivery) =>
      delivery.gifts.map((deliveryGift) => deliveryGift.giftId !== gift.id)
    )
  )

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null
  return (
    <Container>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Item>
        <Item>
          <Label>Total order value:</Label>
          <Value>
            <Money
              amount={parseInt(`${order?.totals?.grandTotal ?? 0}`)}
              currencyUnit={order?.currencyCode as DineroFactory.Currency}
            />
          </Value>
        </Item>
      </Summary>
      {completedDeliveries?.length === 0 && notCompletedDeliveries?.length === 0 ? (
        <PrimaryButton handleClick={createOrderDelivery} loading={createLoading}>
          Create delivery draft
        </PrimaryButton>
      ) : (
        <>
          <h2>Order lines ({orderLinesWithoutCompletedDeliveries?.length ?? 0})</h2>
          {orderLinesWithoutCompletedDeliveries?.map((orderLine) => (
            <VariantRow
              key={orderLine?.id}
              orderLine={orderLine}
              order={order}
              refetch={refetch}
              currencyCode={order?.currencyCode}
              deliveries={order?.Deliveries}
            />
          ))}
          {giftsWithoutCompletedDeliveries && giftsWithoutCompletedDeliveries?.length > 0 && (
            <>
              <h2>Order gifts ({giftsWithoutCompletedDeliveries?.length ?? 0})</h2>
              {giftsWithoutCompletedDeliveries?.map((gift) => (
                <GiftRow
                  key={gift?.id}
                  gift={gift}
                  order={order}
                  refetch={refetch}
                  currencyCode={order?.currencyCode}
                  deliveries={order?.Deliveries}
                />
              ))}
            </>
          )}
          <h2>Shipping ({shippingFeesWithoutCompletedDeliveries?.length ?? 0})</h2>
          {shippingFeesWithoutCompletedDeliveries?.map((shippingFee) => (
            <ShippingRow
              key={shippingFee?.id}
              shippingFee={shippingFee}
              order={order}
              refetch={refetch}
              currencyCode={order?.currencyCode}
              deliveries={order?.Deliveries}
            />
          ))}
          {giftCardsWithoutCompletedDeliveries?.length ?? 0 ? (
            <>
              <h2>Gift card({giftCardsWithoutCompletedDeliveries?.length ?? 0})</h2>
              {giftCardsWithoutCompletedDeliveries?.map((giftCardProduct) => (
                <GiftCardProductRow
                  key={giftCardProduct?.id}
                  giftCardProduct={giftCardProduct}
                  order={order}
                  refetch={refetch}
                  currencyCode={order?.currencyCode}
                  deliveries={order?.Deliveries}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Container>
  )
}
