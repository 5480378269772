import styled from "styled-components/macro"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  position: relative;
  min-width: 16rem;
  min-height: 45rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.8rem;
  padding: 2rem 2rem 2rem 0;
  margin-bottom: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-bottom: 3rem;
  }

  .xAxis {
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
  }
`

export const Totals = styled.div`
  ${(p) => p.theme.bold}
`

export const Label = styled.div`
  ${(p) => p.theme.bold}
  font-size: 1.6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
`

export const PeriodSelection = styled.div`display: flex; align-items: center; gap: 2rem;`

export const IconWrapper = styled.div`
  display: none;
  height: 100%;

  align-items: center;

  svg {
    height: 2.8rem;
    margin-right: 0.5rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    display: flex;
  }
`

export const SelectWrapper = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  div {
    margin-right: 0.5rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: row;
  }
`
