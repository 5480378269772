import React, { useState } from "react"
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { Option, Query } from "@lib/types/common"
import { useQuery } from "@apollo/client"
import esb from "elastic-builder"
import dayjs from "dayjs"
import { ReactComponent as StoreGroupIcon } from "images/icons/circle-nodes.svg"
import { ReactComponent as StoreMarketIcon } from "images/icons/earth.svg"
import {
  IconWrapper,
  Label,
  PeriodSelection,
  SelectWrapper,
  Totals,
  Wrapper
} from "./OrderChart.styled"
import SecondaryButton from "../Ui/Buttons/SecondaryButton"
import PrimaryButton from "../Ui/Buttons/PrimaryButton"
import { Common } from "lib/types"
import MultiSelect from "../Ui/Form/MultiSelect"
import { StoreGroup } from "lib/types/generated/graphql-types"
import { getCountryName } from "helpers/countries"
import ORDERS_TOTAL_HITS_LAST_FIVE_DAYS from "../../graphql/queries/order/LastFiveDaysOrders"
import ORDERS_TOTAL_HITS_24_HOURS from "../../graphql/queries/order/LastTwentyFourHourOrders"
import { isReadOnlyUser } from "helpers/user"

enum RANGE {
  HOURLY,
  DAILY
}

type Props = {
  timeZone: string
  storeGroups: StoreGroup[]
}

export const OrderChart = ({ timeZone, storeGroups }: Props) => {
  const [selectedStoreGroups, setSelectedStoreGroups] = useState<Common.Option[]>([])
  const [selectedStoreMarkets, setSelectedStoreMarkets] = useState<Common.Option[]>([])
  const [range, setRange] = useState<RANGE>(RANGE.HOURLY)

  const getHourQuery = (hour: number) => {
    const boolQuery = esb.boolQuery()
    boolQuery.must([
      esb
        .rangeQuery("date")
        .gte(dayjs().subtract(hour, "hour").startOf("hour").format("YYYY-MM-DD-HH:mm"))
        .lte(dayjs().subtract(hour, "hour").endOf("hour").format("YYYY-MM-DD-HH:mm"))
        .format("yyyy-MM-dd-HH:mm")
        .timeZone(timeZone)
    ])
    if (selectedStoreGroups.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "storeGroupId",
          selectedStoreGroups.map((option) => option.value)
        )
      )
    }
    if (selectedStoreMarkets.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "countryCode",
          selectedStoreMarkets.map((option) => option.value)
        )
      )
    }
    const query = esb.requestBodySearch().query(boolQuery).toJSON() as Query
    return JSON.stringify(query.query)
  }

  const getDayQuery = (days: number) => {
    const boolQuery = esb.boolQuery()
    boolQuery.must([
      esb
        .rangeQuery("date")
        .gte(
          dayjs()
            .subtract(days + 1, "days")
            .startOf("day")
            .format("YYYY-MM-DD-HH:mm")
        )
        .lt(dayjs().subtract(days, "days").startOf("day").format("YYYY-MM-DD-HH:mm"))
        .format("yyyy-MM-dd-HH:mm")
        .timeZone(timeZone)
    ])
    if (selectedStoreGroups.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "storeGroupId",
          selectedStoreGroups.map((option) => option.value)
        )
      )
    }
    if (selectedStoreMarkets.length > 0) {
      boolQuery.must(
        esb.termsQuery(
          "countryCode",
          selectedStoreMarkets.map((option) => option.value)
        )
      )
    }
    const query = esb.requestBodySearch().query(boolQuery).toJSON() as Query
    return JSON.stringify(query.query)
  }

  const getStoreMarketOptions = () => {
    const storeMarkets = storeGroups
      ?.map((storeGroup: StoreGroup) => storeGroup.storeMarkets)
      .flat()
    const countryCodes = storeMarkets?.map((storeMarket) => storeMarket?.countryCode)
    const uniqueCountryCodes = [...new Set(countryCodes)] as string[]
    return uniqueCountryCodes
      .map(
        (code: string) =>
          ({
            value: code,
            label: getCountryName(code)
          } as Option)
      )
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  const dailyQueryArray = [...Array(5)].map((_, index) => ({
    [`query${index + 1}`]: getDayQuery(4 - index)
  }))
  const dailyQueryObject = Object.assign({}, ...dailyQueryArray)
  const { data: dailyData } = useQuery(ORDERS_TOTAL_HITS_LAST_FIVE_DAYS, {
    variables: {
      from: 0,
      size: 1000,
      ...dailyQueryObject,
      trackTotalHits: true
    }
  })

  const hourQueryArray = [...Array(25)].map((_, index) => ({
    [`query${index + 1}`]: getHourQuery(24 - index)
  }))
  const hourQueryObject = Object.assign({}, ...hourQueryArray)
  const { data: hourData } = useQuery(ORDERS_TOTAL_HITS_24_HOURS, {
    variables: {
      from: 0,
      size: 1000,
      ...hourQueryObject,
      trackTotalHits: true
    }
  })

  const getHourData = (data: { [key: string]: { total: number } }) => {
    return Object?.values(data)?.map((value, index) => ({
      Orders: value.total,
      label: dayjs()
        .subtract(24 - index, "hours")
        .format("HH")
    }))
  }

  const getDayData = (data: { [key: string]: { total: number } }) => {
    return Object?.values(data)?.map((value, index) => ({
      Orders: value.total,
      label: dayjs()
        .subtract(5 - index, "days")
        .format("YYYY-MM-DD")
    }))
  }

  const ordersByHour = getHourData(hourData ?? {})
  const ordersByDays = getDayData(dailyData ?? {})

  return (
    <>
      <Wrapper>
        <Label>
          <div>{`Order count ${range === RANGE.DAILY ? "last 5 days" : "last 24h"}`}</div>

          <div>
            <PeriodSelection>
              <Totals>
                Total orders:{" "}
                {range === RANGE.DAILY &&
                  ordersByDays?.reduce(
                    (acc: number, order: { Orders: number; label: string }) => acc + order.Orders,
                    0
                  )}
                {range === RANGE.HOURLY &&
                  ordersByHour?.reduce(
                    (acc: number, order: { Orders: number; label: string }) => acc + order.Orders,
                    0
                  )}
              </Totals>

              {range === RANGE.HOURLY ? (
                <SecondaryButton
                  handleClick={() => setRange(RANGE.DAILY)}
                  overrideDisabled={isReadOnlyUser()}
                >
                  Last 5 days
                </SecondaryButton>
              ) : (
                <PrimaryButton
                  handleClick={() => setRange(RANGE.DAILY)}
                  overrideDisabled={isReadOnlyUser()}
                >
                  Last 5 days
                </PrimaryButton>
              )}
              {range === RANGE.DAILY ? (
                <SecondaryButton
                  handleClick={() => setRange(RANGE.HOURLY)}
                  overrideDisabled={isReadOnlyUser()}
                >
                  {" "}
                  Last 24hs
                </SecondaryButton>
              ) : (
                <PrimaryButton
                  handleClick={() => setRange(RANGE.HOURLY)}
                  overrideDisabled={isReadOnlyUser()}
                >
                  Last 24hs
                </PrimaryButton>
              )}
            </PeriodSelection>
          </div>
        </Label>
        <SelectWrapper>
          <IconWrapper>
            <StoreGroupIcon />
          </IconWrapper>
          <MultiSelect
            overrideDisable={isReadOnlyUser()}
            options={storeGroups?.map((storeGroup: StoreGroup) => ({
              value: storeGroup.id,
              label: storeGroup.name
            }))}
            placeholder={"All store groups"}
            setValue={setSelectedStoreGroups}
          />
          {getStoreMarketOptions()?.length > 0 && (
            <>
              <IconWrapper>
                <StoreMarketIcon />
              </IconWrapper>
              <MultiSelect
                overrideDisable={isReadOnlyUser()}
                placeholder={"Select store market"}
                options={getStoreMarketOptions()}
                setValue={setSelectedStoreMarkets}
              />
            </>
          )}
        </SelectWrapper>

        <>
          {range === RANGE.HOURLY && (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={ordersByHour}
                margin={{ left: 0, right: 30, top: 60, bottom: 30 }}
                height={500}
              >
                <XAxis dataKey="label" className="xAxis" tickMargin={15} />
                <YAxis />
                <Line
                  type="monotone"
                  dataKey="Orders"
                  animationDuration={900}
                  stroke="#91ecda"
                  fill="#f4fdfb"
                  strokeWidth={5}
                  dot={{ stroke: "#91ecda", strokeWidth: 5 }}
                  activeDot={{ stroke: "#91ecda", strokeWidth: 7 }}
                />
                <Tooltip cursor={false} itemStyle={{ color: "black", fontWeight: 700 }} />
              </LineChart>
            </ResponsiveContainer>
          )}
          {range === RANGE.DAILY && (
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={ordersByDays}
                margin={{ left: 0, right: 30, top: 60, bottom: 30 }}
                height={500}
              >
                <XAxis dataKey="label" className="xAxis" tickMargin={15} />
                <YAxis />
                <Line
                  type="monotone"
                  dataKey="Orders"
                  animationDuration={900}
                  stroke="#91ecda"
                  fill="#f4fdfb"
                  strokeWidth={5}
                  dot={{ stroke: "#91ecda", strokeWidth: 5 }}
                  activeDot={{ stroke: "#91ecda", strokeWidth: 7 }}
                />
                <Tooltip cursor={false} itemStyle={{ color: "black", fontWeight: 700 }} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      </Wrapper>
    </>
  )
}

export default OrderChart
