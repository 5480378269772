import React from "react"
import { Order, OrderDelivery, OrderLine } from "lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Id, Name, ProductImage, Row } from "../Shared.styled"

import placeholder from "../../../../../images/placeholder.svg"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import CREATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/CreateDelivery"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { ReactComponent as DeliverIcon } from "../../../../../images/icons/truck-ramp-box.svg"
import Status from "../../../../Ui/Status"
import { handleErrorMessages } from "helpers/errors"
import { getDeliveryInput } from "../Helpers/helpers"

type Props = {
  orderLine: OrderLine
  order: Order
  currencyCode: string
  deliveries: OrderDelivery[]
  refetch: () => void
}

export const VariantRow = ({ orderLine, order, currencyCode, deliveries, refetch }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const notCompletedDelivery = deliveries.find(
    (delivery) => !delivery.started || (delivery.started && !delivery.completed)
  )
  const completedDeliveries = deliveries.filter((delivery) => !!delivery.completed)
  const orderLineDelivered = completedDeliveries.map((delivery) =>
    delivery.orderLines.find((ol) => ol.orderLineId === orderLine.id)
  )
  const getDeliveredQuantity = () =>
    orderLineDelivered.reduce((a, b) => {
      return a + (b?.quantity ?? 0)
    }, 0)

  const [createDelivery, { loading: createLoading }] = useMutation(CREATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const [updateDelivery, { loading: updateLoading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleErrorMessages(error)
    }
  })

  const orderLineExistInDelivery = (id: string) => {
    return notCompletedDelivery?.orderLines.find((ol) => ol.orderLineId === id)
  }

  const getOrderLine = () => {
    return order?.orderLines?.find((ol) => ol.id === orderLine.id)
  }

  const createOrUpdateOrderDelivery = () => {
    if (!notCompletedDelivery) {
      return createDelivery({
        variables: {
          orderId: order.id,
          input: {
            orderLines: {
              orderLineId: orderLine.id,
              quantity: orderLine.quantity - getDeliveredQuantity()
            }
          }
        }
      })
    }
    return updateDelivery({
      variables: {
        deliveryId: notCompletedDelivery.id,
        input: {
          ...getDeliveryInput(notCompletedDelivery),
          orderLines: [
            ...notCompletedDelivery.orderLines.map((ol) => ({
              orderLineId: ol.orderLineId,
              quantity: ol.quantity
            })),
            { orderLineId: orderLine.id, quantity: orderLine.quantity - getDeliveredQuantity() }
          ]
        }
      }
    })
  }

  return (
    <Row key={orderLine.id}>
      <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{getOrderLine()?.name}</Name>
        <Money
          amount={orderLine.totalPriceAmount}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>

        <div>Order quantity: {orderLine.quantity}</div>
        {getDeliveredQuantity() > 0 && <div>Delivered quantity: {getDeliveredQuantity()}</div>}
        <Id>
          <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
        </Id>
      </div>
      <DeliveryButtonWrapper>
        {getDeliveredQuantity() === orderLine.quantity ? (
          <Status status="Delivered" />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderDelivery}
            disabled={!!orderLineExistInDelivery(orderLine.id)}
            loading={createLoading || updateLoading}
          >
            <DeliverIcon />
            Deliver
          </SecondaryButton>
        )}
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={orderLine.totalPriceAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
