import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components/macro"
import dayjs from "dayjs"
import { useMutation, useQuery } from "@apollo/client"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { Group, InputUpdateUser } from "lib/types/generated/graphql-types"

import UPDATE_USER from "graphql/mutations/user/UpdateUser"
import ENABLE_USER from "graphql/mutations/user/EnableUser"
import DISABLE_USER from "graphql/mutations/user/DisableUser"
import ADD_USER_TO_GROUP from "graphql/mutations/user/AddUserToGroup"
import REMOVE_USER_FROM_GROUP from "graphql/mutations/user/RemoveUserFromGroup"
import GROUPS from "graphql/queries/user/Groups"
import USER from "graphql/queries/user/User"
import RESEND_PASSWORD from "graphql/mutations/user/ResendPassword"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import Input from "components/Ui/Form/Input"
import Toggle from "components/Ui/Toggle"
import MultiSelect from "components/Ui/Form/MultiSelect"
import Status from "components/Ui/Status"
import { ReactComponent as UserIcon } from "images/icons/user-gear.svg"
import { ReactComponent as PaperPlaneIcon } from "images/icons/paper-plane.svg"
import { isUserAdmin } from "helpers/user"

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
`

const Password = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AccessInfo = styled.div`
  margin-top: 2rem;
`

type Props = {
  editUser: string
  setEditUser: Dispatch<SetStateAction<string>>
  refetch: () => void
}

export const UpdateUser = ({ editUser, setEditUser, refetch }: Props) => {
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<InputUpdateUser>()
  const [isEnabled, setIsEnabled] = useState<boolean>()
  const [addedGroups, setAddedGroups] = useState<string[]>([])
  const [groupList, setGroupList] = useState<Group[]>([])
  const [passwordButtonDisabled, setPasswordButtonDisabled] = useState(false)

  const { loading, error, data } = useQuery(USER, {
    variables: { username: editUser },
    onCompleted: (data) => {
      setIsEnabled(data.user.enabled)
      setAddedGroups(data.user.groups.map((group: Group) => group.name))
    }
  })

  const { data: groupsData, loading: groupsLoading } = useQuery(GROUPS, {
    variables: { limit: 10 },
    onCompleted: (data) => setGroupList(data.groups.groups)
  })

  const [resendPassword, { loading: passwordLoading }] = useMutation(RESEND_PASSWORD, {
    variables: { username: editUser },
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `New password sent to ${editUser}`,
          type: "success"
        })
      )
      setPasswordButtonDisabled(true)
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [enableUser] = useMutation(ENABLE_USER, {
    variables: { username: editUser },
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `User ${editUser} successfully enabled`,
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [disableUser] = useMutation(DISABLE_USER, {
    variables: { username: editUser },
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `User ${editUser} successfully disabled`,
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `User ${editUser} successfully added to group`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [removeUserFromGroup] = useMutation(REMOVE_USER_FROM_GROUP, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `User ${editUser} successfully removed from group`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const handleEnableUser = (value: boolean) => {
    if (value) {
      enableUser()
    } else {
      disableUser()
    }
    setIsEnabled(value)
  }

  const updateGroups = (value: string[]) => {
    const addedGroup = value.filter((x) => !addedGroups.includes(x))
    const removedGroup = addedGroups.filter((x) => !value.includes(x))
    if (addedGroup.length > 0) {
      addUserToGroup({
        variables: { username: editUser, groupName: addedGroup[0] }
      })
    }

    if (removedGroup.length > 0) {
      removeUserFromGroup({
        variables: { username: editUser, groupName: removedGroup[0] }
      })
    }

    setAddedGroups(value)
  }

  const closeSidebar = () => {
    dispatch(hideEditSidebar())
    setPasswordButtonDisabled(false)
    setEditUser("")
  }

  const [updateUser, { loading: createLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `User ${editUser} successfully updated`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<InputUpdateUser> = (data) => {
    updateUser({
      variables: {
        ...data,
        username: editUser
      }
    })
  }

  if (loading || groupsLoading) return <EditSidebar loading={true} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading User. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>. Error loading User. Try
            refreshing the page, or contact <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const { username, givenName, familyName, created, updated, status, groups } = data.user

  return (
    <EditSidebar setEditId={setEditUser} cancelEvent={closeSidebar}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          title={"Merchant Portal user"}
          setEditId={setEditUser}
          id={username}
          icon={<UserIcon />}
        >
          <PrimaryButton type="submit" loading={createLoading} overrideDisabled={isUserAdmin()}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Row>
          <Label>Enabled:</Label>
          <Toggle value={isEnabled} setValue={handleEnableUser} />
        </Row>
        <Row>
          <Label>Created:</Label>
          <div>{dayjs(created).format("YYYY-MM-DD")}</div>
        </Row>
        <Row>
          <Label>Updated:</Label>
          <div>{dayjs(updated).format("YYYY-MM-DD")}</div>
        </Row>
        <Row>
          <Label>Status:</Label>
          <div>
            <Status status={status} />
          </div>
        </Row>
        {status === "FORCE_CHANGE_PASSWORD" && (
          <Password>
            <PrimaryButton
              type="button"
              handleClick={() => resendPassword()}
              loading={passwordLoading}
              disabled={passwordButtonDisabled}
            >
              <PaperPlaneIcon />
              Resend password
            </PrimaryButton>
          </Password>
        )}
        <hr />
        <MultiSelect
          label="Access"
          setValue={updateGroups}
          returnValueOnly={true}
          overrideDisable={isUserAdmin()}
          defaultValue={groups.map((g: Group) => ({
            value: g.name,
            label: g.name
          }))}
          options={groupsData.groups.groups.map((g: Group) => ({
            value: g.name,
            label: g.name
          }))}
        />
        {addedGroups?.map((groupName) => (
          <AccessInfo key={groupName}>
            <Label>{groupName}</Label>
            <div>{groupList?.find((g) => g.name === groupName)?.description}</div>
          </AccessInfo>
        ))}
        <hr />
        <Controller
          name="givenName"
          render={({ field }) => (
            <Input
              {...field}
              overrideDisabled={isUserAdmin()}
              type="text"
              label="Given name *"
              placeholder="Given name"
              errors={errors}
            />
          )}
          defaultValue={givenName}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="familyName"
          render={({ field }) => (
            <Input
              {...field}
              overrideDisabled={isUserAdmin()}
              type="text"
              label="Family name *"
              placeholder="Family name"
              errors={errors}
            />
          )}
          defaultValue={familyName}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
      </form>
    </EditSidebar>
  )
}
