import React, { ReactNode } from "react"
import Input from "../../../../Ui/Form/Input"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as PaymentIcon } from "images/icons/credit-card.svg"
import { ReactComponent as GiftCardIcon } from "images/icons/gift-card.svg"
import { ReactComponent as BonusIcon } from "images/icons/hand-holding-dollar.svg"
import {
  Order,
  OrderGiftCardBsgGiftCard,
  OrderGiftCardEmpty,
  OrderGiftCardKbsGiftCard,
  OrderGiftCardRetain24
} from "@lib/types/generated/graphql-types"
import {
  Error,
  GiftCardContainer,
  GiftCardId,
  GiftCardLabel,
  InputContainer,
  InputWrapper,
  PaymentContainer,
  PaymentLabel,
  PaymentMethod,
  Total,
  TotalRow,
  Totals
} from "./CompensationTotals.styled"

type Compensation = {
  payment?: { amount: number }
  bonus?: { amount: number }
  giftCards?: { amount: number; giftCardId: string }[]
}

type Props = {
  order: Order
  updatePayment: (amount: number) => void
  updateGiftCard: (amount: number, id: string) => void
  updateBonus: (amount: number) => void
  amount: number
  icon: ReactNode
  maxAmount: number
  amountFromLines: number
  compensation: Compensation | undefined
  totalCompensationAmount: number
  totalGiftCardAmount: number
}

const CompensationTotals = ({
  order,
  updatePayment,
  updateGiftCard,
  updateBonus,
  compensation,
  amount,
  maxAmount,
  amountFromLines,
  totalCompensationAmount,
  totalGiftCardAmount
}: Props) => {
  const completedCompensations = order?.Compensations?.filter(
    (compensation) => !!compensation.completed
  )

  const compensatedGiftCards = completedCompensations?.flatMap(
    (compensation) => compensation.giftCards
  )

  function isRetain24(
    orderGiftCard:
      | OrderGiftCardBsgGiftCard
      | OrderGiftCardKbsGiftCard
      | OrderGiftCardRetain24
      | OrderGiftCardEmpty
  ): orderGiftCard is OrderGiftCardRetain24 {
    return (orderGiftCard as OrderGiftCardRetain24) !== undefined
  }

  const getCompletedGiftCardAmount = (id: string) => {
    return (
      compensatedGiftCards.find((compensatedGiftCard) => compensatedGiftCard?.giftCardId === id)
        ?.amount ?? 0
    )
  }

  return (
    <InputWrapper>
      <h2>Totals</h2>
      {order?.totals.grandTotal > 0 && (
        <PaymentContainer>
          <PaymentLabel>
            <span>
              <PaymentIcon />
              <b>Payment</b>
            </span>
            <PaymentMethod>
              <div>{order?.Payment?.method}</div>
              <b>{order?.Payment?.providerName}</b>
            </PaymentMethod>
          </PaymentLabel>
          <InputContainer>
            <Input
              name="paymentAmount"
              type="number"
              value={compensation?.payment?.amount ?? ""}
              placeholder={
                compensation?.payment && compensation?.payment?.amount > 0
                  ? `${compensation?.payment?.amount}`
                  : "Amount in minor units"
              }
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                updatePayment(parseInt(e.currentTarget.value))
              }
            />
            <div>
              <b>Formatted:</b>{" "}
              <Money
                amount={compensation?.payment?.amount || 0}
                currencyUnit={order.currencyCode as DineroFactory.Currency}
              />
              {amount > maxAmount && <Error>{`- Amount can't be higher than total`}</Error>}
              {amount < 0 && <Error>{`- Amount can´t be lower than 0`}</Error>}
            </div>
          </InputContainer>
        </PaymentContainer>
      )}
      {order?.Bonus && order?.Bonus?.amount > 0 && (
        <PaymentContainer>
          <PaymentLabel>
            <span>
              <BonusIcon />
              <b>Bonus</b>
            </span>
            <PaymentMethod>
              <div>{order?.Bonus?.providerName}</div>
            </PaymentMethod>
          </PaymentLabel>
          <InputContainer>
            <Input
              name="bonusAmount"
              type="number"
              value={compensation?.bonus?.amount ?? ""}
              placeholder={"Amount in minor units"}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                updateBonus(parseInt(e.currentTarget.value))
              }
            />
            <div>
              <b>Formatted:</b>{" "}
              <Money
                amount={compensation?.bonus?.amount || 0}
                currencyUnit={order.currencyCode as DineroFactory.Currency}
              />
              {(compensation?.bonus?.amount ?? 0) > (order.Bonus?.amount ?? 0) && (
                <Error>{`- Amount can't be higher than total`}</Error>
              )}
              {amount < 0 && <Error>{`- Amount can´t be lower than 0`}</Error>}
            </div>
          </InputContainer>
        </PaymentContainer>
      )}
      {isRetain24(order.GiftCard) &&
        order.GiftCard.giftCards?.map((giftCard) => {
          return (
            <GiftCardContainer key={giftCard?.id}>
              <GiftCardLabel>
                <span>
                  <GiftCardIcon />
                  <b>Gift card</b>
                </span>

                <GiftCardId>
                  <div>{giftCard?.id}</div>
                  <b>
                    <Money
                      amount={giftCard.amount - getCompletedGiftCardAmount(giftCard?.id)}
                      currencyUnit={order.currencyCode as DineroFactory.Currency}
                    />
                  </b>
                </GiftCardId>
              </GiftCardLabel>
              <InputContainer>
                <Input
                  name="amount"
                  placeholder={"Amount in minor units"}
                  type="number"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    !e.currentTarget.value
                      ? updateGiftCard(0, giftCard.id)
                      : updateGiftCard(parseInt(e.currentTarget.value), giftCard.id)
                  }}
                />

                <div>
                  <b>Formatted: </b>
                  <Money
                    amount={
                      compensation?.giftCards?.find(
                        (compensationGiftCard) => compensationGiftCard.giftCardId === giftCard.id
                      )?.amount ?? 0
                    }
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                  {(compensation?.giftCards?.find(
                    (compensationGiftCard) => compensationGiftCard.giftCardId === giftCard.id
                  )?.amount ?? 0) >
                  giftCard.amount - getCompletedGiftCardAmount(giftCard?.id) ? (
                    <Error>{`- Amount can't be higher than total`}</Error>
                  ) : (
                    <></>
                  )}
                </div>
              </InputContainer>
            </GiftCardContainer>
          )
        })}
      <Totals>
        <TotalRow>
          <b>Payment </b>
          <div>
            <Money
              amount={compensation?.payment?.amount ?? 0}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </div>
        </TotalRow>
        {isRetain24(order.GiftCard) && totalGiftCardAmount > 0 && (
          <TotalRow>
            <b>GiftCard</b>
            <div>
              <Money
                amount={totalGiftCardAmount}
                currencyUnit={order.currencyCode as DineroFactory.Currency}
              />
            </div>
          </TotalRow>
        )}
        {order.Bonus && (
          <TotalRow>
            <b>Bonus</b>
            <div>
              <Money
                amount={compensation?.bonus?.amount ?? 0}
                currencyUnit={order.currencyCode as DineroFactory.Currency}
              />
            </div>
          </TotalRow>
        )}
        {totalCompensationAmount - amountFromLines !== 0 && (
          <>
            <TotalRow>
              <b>Amount from order lines</b>
              <div>
                <Money
                  amount={amountFromLines}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </div>
            </TotalRow>
            <TotalRow>
              {totalCompensationAmount - amountFromLines < 0 ? (
                <b>Amount from order lines not included in compensation</b>
              ) : (
                <b>Amount not linked to order lines</b>
              )}
              <div>
                <Money
                  amount={totalCompensationAmount - amountFromLines}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </div>
            </TotalRow>{" "}
          </>
        )}
        <Total>
          <b>Compensation total</b>{" "}
          <b>
            <Money
              amount={totalCompensationAmount}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </b>
        </Total>
      </Totals>
    </InputWrapper>
  )
}

export default CompensationTotals
