import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"
import ALL_INVENTORIES from "graphql/queries/inventory/AllInventories"
import SEARCH_DISCOUNT_CODE_RULES from "graphql/queries/discount/SearchDiscountCodeRules"
import SEARCH_DISCOUNT_RULES from "graphql/queries/discount/SearchDiscountRules"
import SEARCH_TAX_GROUPS from "graphql/queries/tax/SearchTaxGroups"

import { DailyOrders } from "components/Dashboard/DailyOrders"
import { CountCard } from "components/Dashboard/CountCard"
import PageHeader from "components/Ui/Page/PageHeader"
import OrderChart from "../components/Dashboard/OrderChart"
import Providers from "components/Dashboard/Providers"
import CartRules from "components/Dashboard/CartRules"
import DiscountCodes from "components/Dashboard/DiscountCodes"

import { ReactComponent as StoreIcon } from "images/icons/store.svg"
import { ReactComponent as InventoryIcon } from "images/icons/warehouse.svg"
import { ReactComponent as TaxIcon } from "images/icons/badge-percent.svg"
import { useSelector } from "react-redux"
import { selectUserAttributes, selectUserGroups } from "lib/store/services/auth/selectors"
import { UserError } from "./UserError"
import SingleSelect from "../components/Ui/Form/SingleSelect"
import { Option } from "@lib/types/common"
import { Flex, Grid, TimeZoneIcon, TimeZoneWrapper, WelcomeGreeting } from "./Home.styled"
import { isReadOnlyUser } from "helpers/user"

export function Home() {
  const { data: storeGroupsData } = useQuery(ALL_STORE_GROUPS, {
    fetchPolicy: "cache-first"
  })
  const { data: inventoriesData } = useQuery(ALL_INVENTORIES, {
    fetchPolicy: "cache-first"
  })
  const { data: discountCodeRuleData } = useQuery(SEARCH_DISCOUNT_CODE_RULES, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const { data: discountRulesData } = useQuery(SEARCH_DISCOUNT_RULES, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const { data: taxGroupData } = useQuery(SEARCH_TAX_GROUPS, {
    variables: { from: 0, size: 1000 },
    fetchPolicy: "cache-first"
  })

  const navigate = useNavigate()
  const userAttributes = useSelector(selectUserAttributes)
  const userGroups = useSelector(selectUserGroups)
  const [timeZone, setTimeZone] = useState<Option>({
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    label: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const timeZones = Intl.supportedValuesOf("timeZone")

  if (!userGroups) return <UserError />
  return (
    <>
      <PageHeader title="Dashboard" />
      <WelcomeGreeting>
        Welcome back
        {userAttributes?.given_name && (
          <>
            , <span>{userAttributes.given_name}</span>
          </>
        )}
      </WelcomeGreeting>
      <TimeZoneWrapper>
        <TimeZoneIcon />
        <SingleSelect
          overrideDisabled={isReadOnlyUser()}
          options={timeZones.map((timeZone) => ({ value: timeZone, label: timeZone }))}
          setValue={setTimeZone}
          defaultValue={{
            value: Intl.DateTimeFormat().resolvedOptions().timeZone,
            label: Intl.DateTimeFormat().resolvedOptions().timeZone
          }}
        />
      </TimeZoneWrapper>
      <Grid>
        <DailyOrders timeZone={timeZone.value} />
        <CountCard
          icon={StoreIcon}
          count={storeGroupsData?.getStoreGroups?.length}
          text="Store groups"
          onClick={() => navigate("/stores/store-groups")}
        />
        <CountCard
          icon={TaxIcon}
          count={taxGroupData?.searchTaxGroups?.total}
          text="Tax groups"
          onClick={() => navigate("/stores/taxes")}
        />
        <CountCard
          icon={InventoryIcon}
          count={inventoriesData?.getInventories?.length}
          text="Inventories"
          onClick={() => navigate("/stores/inventories")}
        />
        <DiscountCodes discountCodeRules={discountCodeRuleData?.searchDiscountCodeRules} />
        <CartRules cartRules={discountRulesData?.searchDiscountRules} />
      </Grid>
      <Flex>
        <OrderChart timeZone={timeZone.value} storeGroups={storeGroupsData?.getStoreGroups} />
      </Flex>
      <Providers />
    </>
  )
}
