import { OrderDelivery, OrderLine } from "@lib/types/generated/graphql-types"
import Dinero from "dinero.js"
import DineroFactory from "dinero.js"

export const getDeliveryInput = (delivery: OrderDelivery | undefined) => ({
  ...(delivery?.orderLines && {
    orderLines: delivery?.orderLines.map((orderLine) => ({
      orderLineId: orderLine.orderLineId,
      quantity: orderLine.quantity
    }))
  }),
  ...(delivery?.shippingFees && {
    shippingFees: delivery.shippingFees.map((fee) => fee.shippingFeeId)
  }),
  ...(delivery?.gifts && { gifts: delivery.gifts }),
  ...(delivery?.giftCardProducts && {
    giftCardProducts: delivery.giftCardProducts.map((giftCardProduct) => ({
      giftCardProductId: giftCardProduct.giftCardProductId
    }))
  })
})

export const getDistributedDiscountOnQty = (
  editOrderLine: OrderLine,
  currencyCode: string,
  quantity: number
) => {
  return Dinero({
    amount: editOrderLine.distributedTotalDiscountAmount,
    currency: currencyCode as DineroFactory.Currency
  })
    .convertPrecision(3)
    .divide(editOrderLine?.quantity)
    .multiply(quantity)
    .convertPrecision(2)
    .getAmount()
}
